import React, { useState, useEffect } from "react";
import { Modal, DatePicker, Button } from "antd";
import Select from "react-select";
import axios from "axios";
import ApexPieChart from "./ApexPieChart";
import ErrorModal from "./ErrorModal";
import { SuccessModal } from "./SuccessModal";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
const { RangePicker } = DatePicker;

function UserTransactionPieChartModal({
  showChart,
  setShowChart,
  selectedFromDate,
  selectedToDate,
  selectedArticle,
  selectedUser,
  userList,
  articleList,
  fromTouserList,
  ledgerList,
  // rows,
}) {
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [userCode, setUserCode] = useState();
  const [article, setArticle] = useState();
  const [purpose_of_payment, setpurpose_of_payment] = useState();
  const [statusPieList, setStatusPieList] = useState([]);
  const [statusPieList1, setStatusPieList1] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessagePopup, setSuccessMessagePopup] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();
  const location = useLocation();
  const pathname = location.pathname;
  useEffect(() => {
    if (showChart) {
      fetchPieChartData(
        selectedUser,
        selectedArticle,
        selectedFromDate,
        selectedToDate
      );
      setUserCode(selectedUser);
      setArticle(selectedArticle);
      setFromDate(selectedFromDate);
      setToDate(selectedToDate);
    }
  }, [showChart]);

  const fetchPieChartData = async (userCode, article, fromDate, toDate) => {
    try {
      const token = localStorage.getItem("him_user_token");
      let data = {};
      if (pathname == "/daily_transaction_head_office") {
        data = {
          from_date: fromDate ? fromDate?.format("YYYY-MM-DD") : null,
          to_date: toDate ? toDate?.format("YYYY-MM-DD") : null,
          user_code: userCode?.value || null,
          to_code: null,
        };
      } else if (pathname == "/report/hard_copy_of_bill_receivings") {
        data = {
          vendor_code: userCode?.value || null,
          from_date: fromDate ? fromDate?.format("YYYY-MM-DD") : null,
          to_date: toDate ? toDate?.format("YYYY-MM-DD") : null,
          article: article?.value || null,
          chart_type: "to_firm",
        };
      } else {
        data = {
          from_date: fromDate ? fromDate?.format("YYYY-MM-DD") : null,
          to_date: toDate ? toDate?.format("YYYY-MM-DD") : null,
          user_code: userCode?.value || null,
          article: article?.value || null,
        };
      }
      const getApi = () => {
        if (pathname == "/daily_transaction_head_office") {
          return `${process.env.REACT_APP_BASE_URL}/himreport/cashtrack/piechart`;
        } else if (pathname == "/report/hard_copy_of_bill_receivings") {
          return `${process.env.REACT_APP_BASE_URL}/himreport/hardcopy/payment/piechart`;
        } else {
          return `${process.env.REACT_APP_BASE_URL}/himreport/usertransaction/payment/piechart`;
        }
      };
      const response = await axios({
        url: getApi(),
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: data,
      });

      let dataArticle = {};
      if (pathname == "/daily_transaction_head_office") {
        dataArticle = {
          page: 0,
          limit: 40,
          paginate: true,
          from_date: fromDate ? fromDate?.format("YYYY-MM-DD") : null,
          to_date: toDate ? toDate?.format("YYYY-MM-DD") : null,
          from_code: userCode?.value || null,
          to_code: null,
          user_code: null,
          // payment of pur sending
          ledger_head: purpose_of_payment?.value || null,
          article: null,
          site_code: null,
        };
      } else if (pathname == "/report/hard_copy_of_bill_receivings") {
        dataArticle = {
          vendor_code: userCode?.value || null,
          from_date: fromDate ? fromDate?.format("YYYY-MM-DD") : null,
          to_date: toDate ? toDate?.format("YYYY-MM-DD") : null,
          article: article?.value || null,
          chart_type: "article",
        };
      } else {
        dataArticle = {
          from_date: fromDate ? fromDate?.format("YYYY-MM-DD") : null,
          to_date: toDate ? toDate?.format("YYYY-MM-DD") : null,
          user_code: userCode?.value || null,
          article: article?.value || null,
        };
      }
      const getApiAricle = () => {
        if (pathname == "/daily_transaction_head_office") {
          return `${process.env.REACT_APP_BASE_URL}/himreport/headoffice/paymentpurpose/piechart`;
        } else if (pathname == "/report/hard_copy_of_bill_receivings") {
          return `${process.env.REACT_APP_BASE_URL}/himreport/hardcopy/payment/piechart`;
        } else {
          return `${process.env.REACT_APP_BASE_URL}/himreport/usertransaction/article/piechart`;
        }
      };
      const response1 = await axios({
        url: getApiAricle(),
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: dataArticle,
      });
      setStatusPieList(response.data?.data || []);
      setStatusPieList1(response1.data?.data || []);
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      console.error("An error occurred while fetching pie chart data:", error);
    }
  };

  function handleCloseSuccess() {
    setShowSuccess(false);
    setSuccessMessagePopup("");
  }

  //payment
  const getPaymentArray = () => {
    if (pathname == "/daily_transaction_head_office") {
      return {
        name: "Daily Transaction",
        list: statusPieList,
      };
    } else if (pathname === "/report/hard_copy_of_bill_receivings") {
      return {
        name: "Hard copy Bill Recievings",
        list: statusPieList?.map((item) => ({
          total_amount: item.total_amount,
          to_code: item.to_firm,
        })),
      };
    } else {
      return {
        name: "Payment",
        list: statusPieList,
      };
    }
  };
  const getArticleArray = () => {
    if (pathname == "/daily_transaction_head_office") {
      return {
        name: "Purpose of Payment",
        list: statusPieList1?.map((item) => ({
          total_amount: item.total_amount,
          to_code: item.ledger_head_code,
        })),
      };
    } else {
      return {
        name: "Article",
        list: statusPieList1?.map((item) => ({
          total_amount: item.total_amount,
          to_code: item.article,
        })),
      };
    }
  };
  console.log(getArticleArray(), "get article array");
  const getTitle = () => {
    if (pathname == "/daily_transaction_head_office") {
      return "Daily Transactions Pie Chart";
    } else if (pathname === "/report/hard_copy_of_bill_receivings") {
      return "Hard copy Bill Recievings Pie Chart";
    } else {
      return "User Transactions Pie Chart";
    }
  };

  return (
    <>
      <Modal
        title={getTitle()}
        open={showChart}
        onCancel={() => setShowChart(false)}
        destroyOnClose={true}
        width={"69%"}
        // height={"80%"}
        footer={null}
      >
        <div className="d-flex gap-4 align-items-end ">
          <div className="w-45">
            <label htmlFor="" className="mb-10 d-block">
              Date
            </label>
            <RangePicker
              className="input-box gray"
              value={[fromDate, toDate]}
              format={"DD MMM YYYY"}
              onChange={(dates) => {
                setFromDate(dates?.length ? dates[0] : "");
                setToDate(dates?.length ? dates[1] : "");
              }}
            />
          </div>
          {/* <div className="w-45">
            <label htmlFor="" className="mb-10 d-block">
              User
            </label>
            <Select
              value={userCode}
              onChange={(value) => setUserCode(value)}
              options={userList.map((user) => ({
                value: user?.code,
                label: user?.code,
              }))}
              isSearchable
              isClearable
            />
          </div>
          <div className="w-45">
            <label htmlFor="" className="mb-10 d-block">
              Article
            </label>
            <Select
              value={article}
              onChange={(value) => setArticle(value)}
              options={articleList.map((user) => ({
                value: user?.item_name,
                label: user?.item_name,
              }))}
              isSearchable
              isClearable
            />
          </div> */}
          {pathname == "/daily_transaction_head_office" ? (
            <>
              <div className="w-45">
                <label htmlFor="" className="mb-10 d-block">
                  Form
                </label>
                <Select
                  value={userCode}
                  onChange={(value) => setUserCode(value)}
                  options={fromTouserList.map((user) => ({
                    value: user?.code,
                    label: user?.code,
                  }))}
                  isSearchable
                  isClearable
                />
              </div>
              {/* <div className="w-45">
                <label htmlFor="" className="mb-10 d-block">
                  Purpose of payment
                </label>
                <Select
                  value={purpose_of_payment}
                  onChange={(value) => setpurpose_of_payment(value)}
                  options={ledgerList.map((user) => ({
                    value: user?.name,
                    label: user?.name,
                  }))}
                  isSearchable
                  isClearable
                />
              </div> */}
            </>
          ) : (
            <>
              <div className="w-45">
                <label htmlFor="" className="mb-10 d-block">
                  {pathname === "/report/hard_copy_of_bill_receivings"
                    ? "From Vendor"
                    : "User"}
                </label>
                <Select
                  value={userCode}
                  onChange={(value) => setUserCode(value)}
                  options={userList.map((user) => ({
                    value: user?.code,
                    label: user?.code,
                  }))}
                  isSearchable
                  isClearable
                />
              </div>
              <div className="w-45">
                <label htmlFor="" className="mb-10 d-block">
                  Article
                </label>
                <Select
                  value={article}
                  onChange={(value) => setArticle(value)}
                  options={articleList.map((user) => ({
                    value: user?.item_name,
                    label: user?.item_name,
                  }))}
                  isSearchable
                  isClearable
                />
              </div>
            </>
          )}
          <Button
            type="primary"
            onClick={() =>
              fetchPieChartData(userCode, article, fromDate, toDate)
            }
          >
            Refresh
          </Button>
        </div>
        <hr className="my-10" />
        <div className="d-flex justify-content-center ">
          {/* <ApexPieChart statusPieList={statusPieList} name={"Payment"} /> */}
          <ApexPieChart
            statusPieList={getPaymentArray()?.list}
            name={getPaymentArray()?.name}
          />
          <ApexPieChart
            name={getArticleArray()?.name}
            statusPieList={getArticleArray()?.list}
          />
        </div>
      </Modal>
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
      <SuccessModal
        showSuccess={showSuccess}
        successMessagePopup={successMessagePopup}
        handleCloseSuccess={handleCloseSuccess}
      />
    </>
  );
}

export default UserTransactionPieChartModal;
