import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import axios from "axios";
import { useNavigate, useOutletContext } from "react-router-dom";
import Loader from "./Loader";
import Select from "react-select";

function AddMobileCredentials({
  showAddCred,
  handleCloseAddCred,
  handleAddEditMobileCreds,
  isUpdatingCreds,
}) {
  const context = useOutletContext();
  const reports = context?.reports || [];
  const navigate = useNavigate();
  // const reports = JSON.parse(localStorage.getItem("him_reports")) || [];
  const [selectedReports, setSelectedReports] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [userType, setUserType] = useState({
    value: 2,
    label: "User",
  });
  const [assosiatedCodes, setAssosiatedCodes] = useState([]);
  const [vehicleAssigned, setVehicleAssigned] = useState();
  const [vehicleNo, setVehicleNo] = useState();
  const [siteList, setSiteList] = useState([]);
  const [vehiclesList, setVehiclesList] = useState([]);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleClose = () => {
    setUsername("");
    setPassword("");
    setSelectedReports([]);
    handleCloseAddCred();
    setUserType();
    setAssosiatedCodes([]);
    setVehicleAssigned();
    setVehicleNo();
  };

  async function getRoles() {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/getRoles`,
        method: "GET",
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        setRoleList(
          response.data?.data ||
            [
              // {
              //   code: "E001",
              //   name: "E001",
              //   id: 1,
              // },
              // {
              //   code: "V001",
              //   name: "V001",
              //   id: 2,
              // },
            ]
        );
      } else {
        setRoleList([]);
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }

  async function getContractors() {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/vendorcontractorlist`,
        method: "GET",
        params: {
          ledger_master: "Vendor,Office,Contractor,Subcontractor,Employee",
        },
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        setSiteList(response.data?.data);
      } else {
        setSiteList([]);
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }
  async function getVehicles() {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getvehicallist`,
        method: "GET",
        // params: {
        //   ledger_master: "Vehicle",
        // },
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        setVehiclesList(response.data?.data);
      } else {
        setVehiclesList([]);
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }

  useEffect(() => {
    if (showAddCred?.id) {
      setUsername(showAddCred?.him_mobile_user?.username);
      setPassword(showAddCred?.him_mobile_user?.password);
      setSelectedReports(
        showAddCred?.him_mobile_report_accesses?.map(
          (report) => report?.report_id
        ) || []
      );

      setUserType({
        value: showAddCred?.him_mobile_user?.role?.role_id,
        label: showAddCred?.him_mobile_user?.role?.role_name,
      });

      setAssosiatedCodes(
        siteList
          .filter((site) => {
            return showAddCred?.associated_codes?.includes(site?.code);
          })
          .map((site) => ({
            value: site?.code,
            label: site?.code, //+ " - " + site?.name,
          }))
      );
      let vehicle = vehiclesList.find((vehicle) => {
        return showAddCred?.associated_codes?.includes(vehicle?.code);
      });

      if (vehicle?.code) {
        setVehicleAssigned({
          label: vehicle?.code, //+ " - " + vehicle?.name,
          value: vehicle?.code,
        });
        setVehicleNo(vehicle?.vehicle_no);
      }
    }
  }, [showAddCred]);

  useEffect(() => {
    getRoles();
    getContractors();
    getVehicles();
  }, [showAddCred]);

  return (
    <Modal show={showAddCred} onHide={handleClose} animation={true} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Add Mobile Credentials <br /> ({showAddCred?.code}
          {/* -{showAddCred?.name} */})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={(e) =>
            handleAddEditMobileCreds(
              e,
              username,
              password,
              selectedReports,
              showAddCred?.him_ledger_master?.name == "Head Office"
                ? "1"
                : userType?.value,
              [...assosiatedCodes, vehicleAssigned]
                .map((code) => code?.value)
                .join(","),
              vehicleAssigned?.value || null,
              vehicleNo,
              handleClose
            )
          }
        >
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Username
            </label>
            <input
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              className="input-box"
              placeholder="Enter Username"
            />
          </div>

          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Password
            </label>
            <input
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="text"
              className="input-box"
              placeholder="Enter Password"
            />
          </div>
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Assosiated Codes{" "}
              <span title="Vendor, Office, Contractor, Subcontractor, Employee">
                <svg className="icon fs-10">
                  <use href="#icon_eye"></use>
                </svg>
              </span>
            </label>
            <Select
              // className="input-box"
              value={assosiatedCodes.filter(
                (code) => code?.value != vehicleAssigned?.value
              )}
              onChange={setAssosiatedCodes}
              options={siteList.map((user) => ({
                value: user?.code,
                label: user?.code, //+ " - " + user?.name,
              }))}
              placeholder="Select Code"
              isMulti
              isSearchable
              isClearable
            />
          </div>
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Vehicle Assigned
            </label>
            <Select
              // className="input-box"
              // value={assosiatedCodes?.find(
              //   (code) => code?.value == vehicleAssigned
              // )}
              value={vehicleAssigned}
              onChange={(option) => {
                setVehicleAssigned(option);
                setVehicleNo(option?.vehicle_no || "");
              }}
              options={vehiclesList
                ?.filter((vehicle) => {
                  if (vehicle?.associated_codes == showAddCred?.code) {
                    return true;
                  } else if (vehicle?.associated_codes == null) {
                    return true;
                  }
                })
                .map((user) => ({
                  value: user?.code,
                  label: user?.code, //+ " - " + user?.name,
                  vehicle_no: user?.vehicle_no,
                }))}
              placeholder="Select Vehicle"
              isSearchable
              isClearable
            />
          </div>
          {vehicleAssigned && (
            <div className="mb-2">
              <label htmlFor="" className="mb-10 d-block gray">
                Vehicle No
              </label>
              <input
                required={vehicleAssigned}
                value={vehicleNo}
                onChange={(e) => setVehicleNo(e.target.value)}
                type="text"
                className="input-box"
                placeholder="Enter Vehicle No"
              />
            </div>
          )}
          {showAddCred?.him_ledger_master?.name != "Head Office" && (
            <>
              <div className="mb-2">
                <label htmlFor="" className="mb-10 d-block gray">
                  Role
                </label>
                <Select
                  // className="input-box"
                  value={userType}
                  onChange={setUserType}
                  options={roleList.map((user) => ({
                    value: user?.role_id,
                    label: user?.role_name,
                  }))}
                  placeholder="Select Role"
                  isSearchable
                  isClearable
                />
              </div>

              <div className="mb-2">
                <label htmlFor="" className="mb-10 d-block gray">
                  Select reports
                </label>

                <div className="d-flex flex-wrap my-2">
                  {reports
                    ?.filter((report) => report?.status)
                    ?.map((report, index) => {
                      return (
                        <div
                          key={report?.report_id + "reportcheckbox"}
                          className="form-check"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            onChange={(e) => {
                              // console.log(e.target.checked);
                              if (e.target.checked) {
                                setSelectedReports([
                                  ...selectedReports,
                                  report?.report_id,
                                ]);
                              } else {
                                setSelectedReports(
                                  selectedReports.filter(
                                    (item) => item !== report?.report_id
                                  )
                                );
                              }
                            }}
                            checked={selectedReports.includes(
                              report?.report_id
                            )}
                            value={report?.report_id}
                            id={report?.report_id + "id"}
                          />
                          <label
                            class="form-check-label"
                            for={report?.report_id + "id"}
                          >
                            {report?.report_name}
                          </label>
                        </div>
                      );
                    })}
                </div>
              </div>
            </>
          )}

          <div className="mb-2">
            <button
              disabled={isUpdatingCreds}
              type="submit"
              className="button button-brown w-100"
            >
              {isUpdatingCreds ? <Loader /> : "Save"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddMobileCredentials;
