import React, { useState } from "react";
import axios from "axios";
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";

function IDVerification() {
  const [idType, setIdType] = useState("pan");
  const [idNumber, setIdNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState(null);
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessagePopup, setSuccessMessagePopup] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();
  const navigate = useNavigate();

  const validateIdNumber = (value, type) => {
    const patterns = {
      pan: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
      adhaar: /^\d{12}$/,
      gst: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
    };
    return patterns[type].test(value);
  };

  const handleInputChange = (e) => {
    setIdNumber(e.target.value.toUpperCase());
  };

  const fetchDetails = async () => {
    if (!validateIdNumber(idNumber, idType)) {
      setError({
        title: "Validation Error",
        err: `Please enter a valid ${idType} ID number`,
      });
      setShowErrModal(true);
      return;
    }

    setIsLoading(true);
    try {
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/panverification`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: {
          // idType,
          pan: idNumber,
        },
      });

      if (response.data.success) {
        setFetchedData(response.data.data);
      } else {
        setError({ title: "Error", err: response?.data?.msg });
        setShowErrModal(true);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const formatValue = (value) => {
    if (typeof value === "boolean") return value ? "Yes" : "No";
    if (value === "Y") return "Yes";
    if (value === "N") return "No";
    if (!value) return "-";
    return value;
  };

  const formatKey = (key) => {
    return key.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase());
  };

  const shouldDisplayField = (value) => {
    // Don't display these fields
    const excludeFields = ["id", "createdAt", "updatedAt"];

    // Handle null/undefined cases
    if (value === null || value === undefined) {
      return false;
    }

    // Handle objects (like address)
    if (typeof value === "object") {
      // Check if it's an empty object
      return Object.keys(value).some(
        (key) =>
          value[key] !== null && value[key] !== undefined && value[key] !== ""
      );
    }

    // Handle primitive values
    return value !== "";
  };

  const renderValue = (value) => {
    if (!value) return <span>-</span>;

    if (typeof value === "object" && value !== null) {
      const hasValidValues = Object.values(value).some((v) => v);
      if (!hasValidValues) return <span>-</span>;

      return (
        <div className="ms-3">
          {Object.entries(value).map(([subKey, subValue]) =>
            subValue ? (
              <div key={subKey} className="d-flex mb-2">
                <strong className="me-2">{formatKey(subKey)}:</strong>
                <span>{formatValue(subValue)}</span>
              </div>
            ) : null
          )}
        </div>
      );
    }
    return <span>{formatValue(value)}</span>;
  };

  function handleCloseSuccess() {
    setshowSuccess(false);
    setSuccessMessagePopup("");
  }

  return (
    <>
      <div className="inner-box pt-3">
        <h1 className="heading p-0 m-0 text-center">ID Verification</h1>
        <div>
          <div className="ms-auto">
            <div className="w-45 mr-30">
              <div className="d-flex align-items-center gap-3 mb-4">
                <select
                  className="input-box gray"
                  value={idType}
                  onChange={(e) => setIdType(e.target.value)}
                >
                  <option value="pan">PAN</option>
                  <option disabled value="adhaar">
                    Adhaar
                  </option>
                  <option disabled value="gst">
                    GST
                  </option>
                </select>
                <input
                  type="text"
                  className="input-box gray"
                  value={idNumber}
                  onChange={handleInputChange}
                  placeholder={`Enter ${idType.toUpperCase()} number`}
                />
                <button
                  type="button"
                  className="button button-brown"
                  onClick={fetchDetails}
                  disabled={isLoading || !idNumber}
                >
                  {isLoading ? <Loader /> : "Fetch Details"}
                </button>
              </div>

              {fetchedData && !isLoading ? (
                <div className="mt-4">
                  <h3>Verification Details</h3>
                  <div className="card p-3">
                    {Object.entries(fetchedData)
                      .filter(
                        ([key]) =>
                          !["id", "createdAt", "updatedAt"].includes(key)
                      )
                      .map(
                        ([key, value]) =>
                          shouldDisplayField(value) && (
                            <div key={key} className="d-flex mb-2 flex-column">
                              <div className="d-flex">
                                <strong className="me-2">
                                  {formatKey(key)}:
                                </strong>
                                {renderValue(value)}
                              </div>
                            </div>
                          )
                      )}
                  </div>
                </div>
              ) : isLoading ? (
                <Loader />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
      <SuccessModal
        showSuccess={showSuccess}
        successMessagePopup={successMessagePopup}
        handleCloseSuccess={handleCloseSuccess}
      />
    </>
  );
}

export default IDVerification;
