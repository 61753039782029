import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";

function AddEditPropertyAppreciation({
  showAddCred,
  handleCloseAddCred,
  handleAddEditMobileCreds,
  isUpdatingCreds,
  label,
}) {
  const navigate = useNavigate();
  const [totalAmountInvested, setTotalAmountInvested] = useState("");
  const [currentProfit, setCurrentProfit] = useState("");

  const handleClose = () => {
    setTotalAmountInvested("");
    setCurrentProfit("");
    handleCloseAddCred();
  };

  useEffect(() => {
    if (showAddCred?.id) {
      setTotalAmountInvested(showAddCred?.total_amount_invested || "");
      setCurrentProfit(showAddCred?.current_profit || "");
    }
  }, [showAddCred]);

  return (
    <Modal
      show={showAddCred}
      onHide={handleClose}
      animation={true}
      scrollable={true}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={(e) =>
            handleAddEditMobileCreds(
              e,
              showAddCred?.id || null,
              totalAmountInvested,
              currentProfit,
              handleClose
            )
          }
        >
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Total Appreciation
            </label>
            <input
              disabled
              value={showAddCred?.total_appreciation || ""}
              type="number"
              className="input-box"
              placeholder="Enter Total Appreciation"
            />
          </div>
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Total Amount Invested
            </label>
            <input
              required
              value={totalAmountInvested}
              onChange={(e) => {
                setTotalAmountInvested(e.target.value);
                setCurrentProfit(
                  (showAddCred?.total_appreciation - e.target.value)?.toFixed(
                    2
                  ) || ""
                );
              }}
              type="number"
              className="input-box"
              placeholder="Enter Total Amount Invested"
            />
          </div>

          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Current Profit
            </label>
            <input
              required
              disabled
              value={currentProfit}
              onChange={(e) => setCurrentProfit(e.target.value)}
              type="number"
              className="input-box"
              placeholder="Enter Current Profit"
            />
          </div>

          <div className="mb-2 mt-4">
            <button
              disabled={isUpdatingCreds}
              type="submit"
              className="button button-brown w-100"
            >
              {isUpdatingCreds ? <Loader /> : "Save"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddEditPropertyAppreciation;
