import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import axios from "axios";
import { useNavigate, useOutletContext } from "react-router-dom";
import Loader from "./Loader";
import Select from "react-select";

function AddEditDailyVendorRequirement({
    showAddCred,
    handleCloseAddCred,
    handleAddEditMobileCreds,
    isUpdatingCreds,
    label,
    articlesList,
    userList,
    selectedArticleParent,
    selectedUserParent
}) {
    // "trans_date":"2024-10-10",
    //   "payment_type":"1",
    //   "from_code":"E001",
    //   "to_code":"V001",
    //   "amount":"1000"

    const navigate = useNavigate();
    const [date, setDate] = useState("");

    const [fromCode, setFromCode] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [toCode, settoCode] = useState("");
    // const [toFirm, setToCode] = useState("");
    const [vendorCode, setVendorCode] = useState("");
    const [requirement, setRequirement] = useState("");
    const [invoiceNo, setinvoiceNo] = useState("");
    const [amountPerQuantity, setAmountPerQuantity] = useState("");
    const [via, setVia] = useState("");
    const [type, setType] = useState();
    const [selectedArticle, setSelectedArticle] = useState("");
    const [total, setTotal] = useState("");
    const [quantity, setQuantity] = useState("");

    const [gstRatio, setgstRatio] = useState("");
    const [purpose_of_payment, setpurpose_of_payment] = useState("");
    const [pendingAmountInvoice, setpendingAmountInvoice] = useState("");
    const [vendorContact, setvendorContact] = useState("");
    const [remarks, setRemarks] = useState("");
    const [selectedUser, setselectedUser] = useState("");
    const [selectedVendor, setSelectedVendor] = useState();
    const [vendorList, setVendorList] = useState([]);
    const [vendorContactList, setVendorContactList] = useState([]);

    const typeOptions = [
        { value: 1, label: "Quantity" },
        { value: 2, label: "Total Amount" },
    ];

    const handleClose = () => {
        setDate("");

        setContactPerson("");
        setVendorCode("");
        setRequirement("");
        setinvoiceNo("");
        setgstRatio("");
        setpurpose_of_payment("");
        setpendingAmountInvoice("");
        setvendorContact("");
        setRemarks("");
        setSelectedArticle("");
        setselectedUser("");
        handleCloseAddCred();
    };

    async function getContractors(type) {
        try {
            const token = localStorage.getItem("him_user_token");

            const response = await axios({
                url: `${process.env.REACT_APP_BASE_URL}/himreport/updatehardcopybill`,
                method: "GET",
                params: {
                    ledger_master: type || "",
                },
                headers: {
                    jwt_token: token,
                },
            });

            if (response.status === 200) {
                // setUserList(
                return response.data?.data || [];
                // [
                //   // {
                //   //   code: "E001",
                //   //   name: "E001",
                //   //   id: 1,
                //   // },
                //   // {
                //   //   code: "V001",
                //   //   name: "V001",
                //   //   id: 2,
                //   // },
                // ]
                // );
            } else {
                return [];
            }
        } catch (error) {
            if (error?.response?.status == 403) {
                localStorage.removeItem("him_user_token");
                localStorage.removeItem("him_user_data");
                navigate("/login");
                return [];
            }

            if (error?.code === "ECONNABORTED") {
                console.log("Request timed out");
            } else {
                console.log("An error occurred:", error);
            }
        }
    }
    async function getAllEmployees() {
        try {
            const token = localStorage.getItem("him_user_token");

            const response = await axios({
                url: `${process.env.REACT_APP_BASE_URL}/himreport/vendorcontractorlist`,
                method: "GET",
                params: {
                    ledger_master: "Vendor",
                },
                headers: {
                    jwt_token: token,
                },
            });

            if (response.status === 200) {
                setVendorList(response.data?.data);
            } else {
                setVendorList([]);
            }
        } catch (error) {
            if (error?.response?.status == 403) {
                localStorage.removeItem("him_user_token");
                localStorage.removeItem("him_user_data");
                navigate("/login");
                return;
            }

            if (error?.code === "ECONNABORTED") {
                console.log("Request timed out");
            } else {
                console.log("An error occurred:", error);
            }
        }
    }
    async function getVendorContact() {
        try {
            const token = localStorage.getItem("him_user_token");

            const response = await axios({
                url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/contactperson`,
                method: "GET",

                headers: {
                    jwt_token: token,
                },
            });

            if (response.status === 200) {
                return setVendorContactList(response.data?.data || []);
            } else {
                return setVendorContactList([]);
            }
        } catch (error) {
            if (error?.response?.status == 403) {
                localStorage.removeItem("him_user_token");
                localStorage.removeItem("him_user_data");
                navigate("/login");
                return;
            }

            if (error?.code === "ECONNABORTED") {
                console.log("Request timed out");
            } else {
                console.log("An error occurred:", error);
            }
        }
    }

    useEffect(() => {
        if (showAddCred?.id) {
            // let selected_article = articlesListusers.find(
            //     (item) => item.code === showAddCred?.location
            // );
            setDate(showAddCred?.date);

            setContactPerson(showAddCred?.contact_person ? {
                value: showAddCred?.contact_person,
                label: showAddCred?.contact_person
            } : null);
            setVendorCode(showAddCred?.vendor_code ? {
                value: showAddCred?.vendor_code,
                label: showAddCred?.vendor_code
            } : null);
            setRequirement(showAddCred?.requirement);

            // setinvoiceNo(showAddCred?.invoice_number)
            // setgstRatio(showAddCred?.gst_ratio);
            // setpurpose_of_payment(showAddCred?.ledger_head);
            // setpendingAmountInvoice(showAddCred?.pending_amount_invoices);
            // setvendorContact(showAddCred?.vendor_contact_person);
            // setSelectedArticle(showAddCred?.article);
            setRemarks(showAddCred?.remarks);
        }
        getAllEmployees();
        getVendorContact();
    }, [showAddCred]);
    console.log(showAddCred, "showadd cred")

    useEffect(() => {
        if (type?.value == 1) {
            const calculatedTotal =
                parseFloat(quantity) * parseFloat(amountPerQuantity);
            setTotal(calculatedTotal);
        }
    }, [quantity, amountPerQuantity]);

    return (
        <Modal show={showAddCred} onHide={handleClose} animation={true} scrollable={true} centered>
            <Modal.Header closeButton>
                <Modal.Title>{label}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form
                    onSubmit={(e) =>
                        handleAddEditMobileCreds(
                            e,
                            showAddCred?.id || null,
                            date,
                            contactPerson?.value,
                            vendorCode?.value,
                            requirement,
                            remarks,
                            handleClose
                        )
                    }
                >
                    <div className="mb-2">
                        <label htmlFor="" className="mb-10 d-block gray">
                            Date
                        </label>
                        <input
                            required
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            type="date"
                            className="input-box"
                            placeholder="Select Date"
                            onFocus={(e) => e.target.showPicker()}
                        />
                    </div>

                    <div className="mb-2">
                        <label htmlFor="" className="mb-10 d-block gray">
                            Contact Person
                        </label>
                        {/* <input
                            required
                            value={contactPerson}
                            onChange={(e) => setContactPerson(e.target.value)}
                            type="text"
                            className="input-box"
                            placeholder="Enter Contact Person"
                        /> */}
                        <Select
                            value={contactPerson}
                            required
                            onChange={(value) => setContactPerson(value)}
                            options={vendorContactList.map((user) => ({
                                value: user?.vendor_contact,
                                label: user?.vendor_contact, //+ "-" + user?.name,
                            }))}
                            placeholder="Select Type"
                            isSearchable
                            isClearable
                        />
                    </div>

                    <div className="mb-2">
                        <label htmlFor="" className="mb-10 d-block gray">
                            Vendor Code
                        </label>
                        {/* <input
                            required
                            value={vendorCode}
                            onChange={(e) => setVendorCode(e.target.value)}
                            type="text"
                            className="input-box"
                            placeholder="Enter Vendor Code"
                        /> */}
                        <Select
                            value={vendorCode}
                            required
                            onChange={(value) => setVendorCode(value)}
                            options={vendorList.map((user) => ({
                                value: user?.code,
                                label: user?.code, // + "-" + user?.name,
                            }))}
                            placeholder="Select Type"
                            isSearchable
                            isClearable
                        />
                    </div>

                    <div className="mb-2">
                        <label htmlFor="" className="mb-10 d-block gray">
                            Requirement
                        </label>
                        <input
                            required
                            value={requirement}
                            onChange={(e) => setRequirement(e.target.value)}
                            type="text"
                            className="input-box"
                            placeholder="Enter Requirement"
                        />
                    </div>

                    <div className="mb-2">
                        <label htmlFor="" className="mb-10 d-block gray">
                            Remarks
                        </label>
                        {/* textarea */}
                        <textarea
                            value={remarks}
                            onChange={(e) => setRemarks(e.target.value)}
                            className="input-box"
                            placeholder="Enter Remarks"
                        ></textarea>
                    </div>
                    {/* <div className="mb-2">
                        <label htmlFor="" className="mb-10 d-block gray">
                            Choose user
                        </label>
                        <Select
                            value={selectedUser}
                            required
                            onChange={(value) => setselectedUser(value)}
                            options={userList.map((user) => ({
                                value: user?.code,
                                label: user?.code, //+ "-" + user?.name,
                            }))}
                            placeholder="Select Type"
                            isSearchable
                            isClearable
                        />
                    </div> */}

                    <div className="mb-2 mt-4">
                        <button
                            disabled={isUpdatingCreds}
                            type="submit"
                            className="button button-brown w-100"
                        >
                            {isUpdatingCreds ? <Loader /> : "Save"}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default AddEditDailyVendorRequirement;
