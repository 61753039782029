import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import axios from "axios";
import * as XLSX from "xlsx";
import Loader from "../../components/Loader";
import ErrorModal from "../../components/ErrorModal";
import { SuccessModal } from "../../components/SuccessModal";
import moment from "moment";
import Select from "react-select";
import { DatePicker } from "antd";
import { Modal } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import { isHo } from "../../utils/comman";
import AddEditVehicleChallan from "../../components/AddEditVehicleChallan";
const { RangePicker } = DatePicker;
function VehicleChallan() {
  // const { report } = useParams();
  const context = useOutletContext();
  const reports = context?.reports || [];
  const componentRef = useRef();

  // const reports = JSON.parse(localStorage.getItem("him_reports")) || [];
  let currentReport =
    reports.find((r) => r.report_code === "vehicle_challan") || {};
  let columns = [
    // {
    //   name: "Vehicle Name",
    //   code: "vehicle_name",
    //   format: (value) => value || "",
    // },
    {
      name: "Vehicle No",
      code: "vehicle_no",
      format: (value) => value || "",
    },
    {
      name: "Vehicle Code",
      code: "vehicle_code",
      format: (value) => value || "",
    },
    {
      name: "Challan Date",
      code: "challan_date",
      format: (value) => (value ? moment(value).format("DD MMM YYYY") : ""),
    },
    {
      name: "Challan No",
      code: "challan_no",
      format: (value) => value || "",
    },
    // {
    //   name: "Due Date",
    //   code: "due_date",
    //   format: (value) => (value ? moment(value).format("DD MMM YYYY") : ""),
    // },
    {
      name: "Due Amount",
      code: "due_amount",
      format: (value) => value || "",
    },
    {
      name: "Status",
      code: "status",
      format: (value) => value || "",
    },
    {
      name: "Challan Location",
      code: "challan_location",
      format: (value) => value || "",
    },
    {
      name: "DL RC Number",
      code: "dl_rc_number",
      format: (value) => value || "",
    },
    {
      name: "Violator Name",
      code: "violator_name",
      format: (value) => value || "",
    },
    {
      name: "Violation Details",
      code: "violation_details",
      format: (value) => {
        if (value) {
          let violationDetails = JSON.parse(value);
          return violationDetails.map((item) => item?.offence).join(", ");
        } else {
          return "";
        }
      },
    },
    {
      name: "Violator Name",
      code: "violator_name",
      format: (value) => value || "",
    },
    // {
    //   name: "Paid Amount",
    //   code: "paid_amount",
    //   format: (value) => value || "",
    // },
    // {
    //   name: "Balance Amount",
    //   code: "balance_amount",
    //   format: (value) => value || "",
    // },
    {
      name: "State",
      code: "state",
      format: (value) => value || "",
    },
    // {
    //   // name: "Ledger Head",
    //   name: "Purpose Of Payment",
    //   code: "head",
    //   format: (value) => value || "",
    // },
    // {
    //   name: "Remarks",
    //   code: "remarks",
    //   format: (value) => value || "",
    // },
    // {
    //   name: "User Name",
    //   code: "employee_name",
    //   format: (value) => value || "",
    // },
    // {
    //   name: "User Code",
    //   code: "employee_code",
    //   format: (value) => value || "",
    // },
  ];
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(40);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isGettingRows, setIsGettingRows] = useState(false);
  const [isFetchingVehicle, setIsFetchingVehicle] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessagePopup, setSuccessMessagePopup] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();
  const [isPrinting, setIsPrinting] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [showFetchConfirmation, setShowFetchConfirmation] = useState(false);
  function handleCloseSuccess() {
    setshowSuccess(false);
    setSuccessMessagePopup("");
  }

  const [selectedUser, setSelectedUser] = useState();
  const [selectedVehicle, setSelectedVehicle] = useState();
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [vehicleList, setVehicleList] = useState([]);
  const handleShowAddCred = (data) => setShowAddCred(data);
  const [showAddCred, setShowAddCred] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState();

  const handleCloseAddCred = () => setShowAddCred(false);
  const [isUpdatingCreds, setIsUpdatingCreds] = useState(false);
  const [articlesList, setArticlesList] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState();

  const navigate = useNavigate();

  async function handleAddEditMobileCreds(
    event,
    id,
    date,
    vehicleCode,
    challanDate,
    challanNo,
    dueDate,
    dueAmount,
    paidAmount,
    balanceAmount,
    status,
    remarks,
    handleCloseCallback
  ) {
    event && event?.preventDefault();

    setIsUpdatingCreds(true);
    try {
      const token = localStorage.getItem("him_user_token");

      let data = {
        id: id || null,
        date,
        vehicle_code: vehicleCode,
        challan_date: challanDate,
        challan_no: challanNo,
        due_date: dueDate,
        due_amount: dueAmount,
        paid_amount: paidAmount,
        balance_amount: balanceAmount,
        status,
        remarks,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/updatehardcopybill`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: data,
      });

      if (response?.data?.success) {
        setshowSuccess(true);
        setSuccessMessagePopup(response.data?.msg);
        setTimeout(() => {
          handleCloseSuccess();
        }, 5000);
        handleCloseCallback ? handleCloseCallback() : handleCloseAddCred();
      } else {
        setError({ title: "Error", err: response?.data?.msg });
        setShowErrModal(true);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsUpdatingCreds(false);
      getRows(selectedUser, selectedFromDate, selectedToDate, selectedArticle);
      // setPage("0");
    }
  }

  async function getRows(selectedUser, selectedFromDate, selectedToDate) {
    try {
      setIsGettingRows(true);
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/getreportdata`,
        method: "GET",
        headers: {
          jwt_token: token,
        },
        params: {
          report_code: currentReport?.report_code || "vehicle_challan",
          vehicle_no: selectedUser?.value || null,
          from_date: selectedFromDate
            ? selectedFromDate?.format("YYYY-MM-DD")
            : null,
          to_date: selectedToDate ? selectedToDate?.format("YYYY-MM-DD") : null,
          page: parseInt(page),
          limit: parseInt(limit),
          paginate: true,
        },
      });

      // Sort rows by challan_date in descending order
      const sortedRows = (response?.data?.data || []).sort((a, b) => {
        return new Date(b.challan_date) - new Date(a.challan_date);
      });

      setRows(sortedRows);
      setTotalCount(response?.data?.count);
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsGettingRows(false);
    }
  }
  async function printOrExportData(
    isPrint,
    selectedUser,
    selectedFromDate,
    selectedToDate,
    rows
  ) {
    try {
      if (isPrint) {
        setIsPrinting(true);
      } else {
        setIsExporting(true);
      }
      const token = localStorage.getItem("him_user_token");
      let tableData = [];
      if (isPrint && rows?.length) {
        tableData = rows;
      } else {
        const response = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/getreportdata`,
          method: "GET",
          headers: {
            jwt_token: token,
          },
          params: {
            report_code: currentReport?.report_code || "vehicle_challan",
            vehicle_no: selectedUser?.value || null,
            from_date: selectedFromDate
              ? selectedFromDate?.format("YYYY-MM-DD")
              : null,
            to_date: selectedToDate
              ? selectedToDate?.format("YYYY-MM-DD")
              : null,
            paginate: false,
          },
        });

        // tableData = response?.data?.data || [];

        // Sort rows by challan_date in descending order
        tableData = (response?.data?.data || []).sort((a, b) => {
          return new Date(b.challan_date) - new Date(a.challan_date);
        });
      }
      if (isPrint) {
        // Open a new window
        const printWindow = window.open("", "");

        // Generate HTML content for the new window
        const tableHtml = `
          <html>
            <head>
              <title>Print</title>
              <style>
                body {
                  font-family: Arial, sans-serif;
                }
                .table-wrapper {
                  width: 100%;
                  border-collapse: collapse;
                }
                .table-wrapper th, .table-wrapper td {
                  border: 1px solid #000;
                  padding: 8px;
                  text-align: left;
                }
                .table-wrapper th {
                  background-color: #f2f2f2;
                }
                .button {
                  padding: 10px 20px;
                  background-color: #AA731F;
                  color: #fff;
                  border: none;
                  cursor: pointer;
                  margin-top: 10px;
                  border-radius: 5px;
                }
                .button:disabled {
                  background-color: #ccc;
                  cursor: not-allowed;
                }
                #selectAll {
                  margin-right: 5px;
                }
                .heading {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 20px;
                  margin-right: 20px;
                }
              </style>
            </head>
            <body>
              <div class="heading">
                <h1>Vehicle Challan</h1>
                <button id="printButton" class="button">Print</button>
              </div>
              <table class="table-wrapper">
                <thead>
                  <tr>
                    <th><input checked type="checkbox" id="selectAll"></th>
                    <th>Sr. No.</th>
                    ${columns
                      .map((column) => `<th>${column.name}</th>`)
                      .join("")}
                  </tr>
                </thead>
                <tbody>
                  ${tableData
                    .map((item, index) => {
                      return `<tr>
                        <td><input checked type="checkbox" class="rowCheckbox"></td>
                        <td class="sr-no">${index + 1}</td>
                        ${columns
                          .map((column) => {
                            return `<td>${
                              column.format
                                ? column.format(item[column.code])
                                : item[column.code]
                            }</td>`;
                          })
                          .join("")}
                      </tr>`;
                    })
                    .join("")}
                </tbody>
              </table>
              <script>
                const printButton = document.getElementById('printButton');
                const checkboxes = document.querySelectorAll('.rowCheckbox');
                const selectAllCheckbox = document.getElementById('selectAll');
  
                function updatePrintButtonState() {
                  const anyChecked = Array.from(checkboxes).some(
                    (checkbox) => checkbox.checked
                  );
                  printButton.disabled = !anyChecked;
                }

                function updateSerialNumbers() {
                const visibleRows = Array.from(document.querySelectorAll('tbody tr')).filter(row => row.style.display !== 'none');
                visibleRows.forEach((row, index) => {
                  row.querySelector('.sr-no').textContent = index + 1;
                });
                }
  
                checkboxes.forEach((checkbox) => {
                  checkbox.addEventListener('change', () => {
                    updatePrintButtonState();
                    if (!checkbox.checked) {
                      selectAllCheckbox.checked = false;
                    }
                  });
                });
  
                selectAllCheckbox.addEventListener('change', () => {
                  const isChecked = selectAllCheckbox.checked;
                  checkboxes.forEach((checkbox) => {
                    checkbox.checked = isChecked;
                  });
                  updatePrintButtonState();
                });
  
                printButton.addEventListener('click', () => {
                  checkboxes.forEach((checkbox) => {
                    if (!checkbox.checked) {
                      checkbox.parentElement.parentElement.style.display = 'none';
                    }
                  });
                  updateSerialNumbers();
                  document.querySelectorAll('input[type="checkbox"]').forEach((el) => 
                    el.parentElement.remove()
                  );
                  printButton.remove();
                  window.print();
                  window.close();
                });
              </script>
            </body>
          </html>
        `;
        // Write content to the new window
        printWindow.document.open();
        printWindow.document.write(tableHtml);
        printWindow.document.close();
      } else {
        const exportData = tableData.map((item, index) => {
          const data = {
            "Sr.No.": parseInt(page) * limit + index + 1,
          };
          columns.forEach((column) => {
            data[column.name] = column.format
              ? column.format(item[column.code])
              : item[column.code];
          });

          return data;
        });
        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, `vehicle_challan.xlsx`);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });

      if (error?.response?.status == 403) {
        console.log("error", error);
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      if (isPrint) {
        setIsPrinting(false);
      } else {
        setIsExporting(false);
      }
    }
  }

  async function getAllVehicles() {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/vendorcontractorlist`,
        method: "GET",
        headers: {
          jwt_token: token,
        },
        params: {
          ledger_master: "vehicle",
        },
      });

      if (response.status === 200) {
        setVehicleList(response.data?.data);
      } else {
        setVehicleList([]);
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }
  // pop-up for delete
  async function handleDelete(row) {
    setIsDeleting(true);
    try {
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/deletevehicalchallan/${row?.id}`,
        // method: "POST",
        method: "DELETE",
        headers: {
          jwt_token: token,
        },
        // data: {
        //   id: row?.id,
        // },
      });

      if (response?.data?.success) {
        setshowSuccess(true);
        setSuccessMessagePopup(response.data?.msg);
        setShowDeleteModal(false);
        setTimeout(() => {
          handleCloseSuccess();
        }, 5000);
      } else {
        setError({ title: "Error", err: response?.data?.msg });
        setShowErrModal(true);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsDeleting(false);
      // setPage("0");
      getRows(selectedUser, selectedFromDate, selectedToDate, selectedLocation);
    }
  }
  async function handleFetchChallanAPI() {
    setIsFetchingVehicle(true);
    try {
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/fetch_vehical_challan`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: {
          vehicle_no: selectedVehicle?.value || null,
        },
      });

      if (response?.data?.success) {
        setshowSuccess(true);
        setSuccessMessagePopup(response.data?.msg);
        setTimeout(() => {
          handleCloseSuccess();
        }, 5000);
      } else {
        setError({ title: "Error", err: response?.data?.msg });
        setShowErrModal(true);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsFetchingVehicle(false);
      setSelectedVehicle(null);
      setShowFetchConfirmation(false);
      getRows(selectedUser, selectedFromDate, selectedToDate, selectedLocation);
    }
  }
  //
  useEffect(() => {
    getRows(selectedUser, selectedFromDate, selectedToDate);
  }, [page, limit]);
  useEffect(() => {
    getAllVehicles();
  }, []);
  return (
    <>
      <div className="inner-box pt-3">
        <div className="d-flex position-relative">
          <div className="w-100 text-center">
            <h1 className="heading p-0 m-0">
              {currentReport?.report_name || ""}
            </h1>
          </div>
          <div
            className="d-flex gap-4 align-items-end position-absolute"
            style={{ right: 0 }}
          >
            <div className="w-45">
              <Select
                value={selectedVehicle}
                onChange={(option) => {
                  setSelectedVehicle(option);
                }}
                options={vehicleList
                  ?.filter((vehicle) => vehicle?.vehicle_no)
                  .map((user) => ({
                    value: user?.vehicle_no,
                    label: user?.vehicle_no,
                  }))}
                isSearchable
                isClearable
              />
            </div>
            <button
              type="button"
              className="button button-brown mr-30"
              onClick={() => setShowFetchConfirmation(true)}
              disabled={isFetchingVehicle || !selectedVehicle?.value}
            >
              {isFetchingVehicle ? <Loader /> : "Fetch Challan"}
            </button>
          </div>
        </div>

        {/* new add */}
        <div className="mb-20 d-flex gray justify-content-between">
          <div className="d-flex gap-4">
            {/* <div className="w-45 mr-30">
              <label htmlFor="" className="mb-10 d-block">
                From Date
              </label>
              <input
                value={selectedFromDate}
                onChange={(e) => setSelectedFromDate(e.target.value)}
                type="date"
                className="input-box gray"
              />
            </div>
            <div className="w-45 mr-30">
              <label htmlFor="" className="mb-10 d-block">
                To Date
              </label>
              <input
                value={selectedToDate}
                onChange={(e) => setSelectedToDate(e.target.value)}
                type="date"
                className="input-box gray"
              />
            </div> */}
            <div className="w-45 ">
              <label htmlFor="" className="mb-10 d-block">
                Date
              </label>
              <RangePicker
                className="input-box gray"
                value={[selectedFromDate, selectedToDate]}
                format={"DD MMM YYYY"}
                onChange={(dates) => {
                  setSelectedFromDate(dates?.length ? dates[0] : "");
                  setSelectedToDate(dates?.length ? dates[1] : "");
                  if (page == 0) {
                    getRows(
                      selectedUser,
                      dates?.length ? dates[0] : "",
                      dates?.length ? dates[1] : ""
                    );
                  } else {
                    setPage(0);
                  }
                }}
              />
            </div>
            <div className="w-45 ">
              <label htmlFor="" className="mb-10 d-block">
                Vehicle No
              </label>

              <Select
                // className="input-box"
                value={selectedUser}
                onChange={(option) => {
                  setSelectedUser(option);
                  if (page == 0) {
                    getRows(option, selectedFromDate, selectedToDate);
                  } else {
                    setPage(0);
                  }
                }}
                options={vehicleList.map((user) => ({
                  value: user?.vehicle_no,
                  label: user?.code + "-" + (user?.vehicle_no || ""),
                }))}
                // placeholder="Select To Code"
                isSearchable
                isClearable
              />
            </div>
          </div>
          <div
            style={{
              alignSelf: "flex-end",
            }}
          >
            {/* <button
              type="button"
              className="button button-brown mr-30"
              onClick={() =>
                printOrExportData(
                  false,
                  selectedUser,
                  selectedFromDate,
                  selectedToDate
                )
              }
              disabled={!rows.length}
            >
              {isExporting ? <Loader /> : "Export"}
            </button> */}

            <select
              value={""}
              onChange={(e) => {
                if (e.target.value == "Print Selection") {
                  printOrExportData(
                    true,
                    selectedUser,
                    selectedFromDate,
                    selectedToDate,
                    rows
                  );
                } else if (e.target.value == "Print All") {
                  printOrExportData(
                    true,
                    selectedUser,
                    selectedFromDate,
                    selectedToDate
                  );
                }
              }}
              className="button button-brown mr-30"
            >
              <option className="d-none" value="">
                {isPrinting ? <Loader /> : "Print"}
              </option>
              <option value="Print Selection">Print Selection</option>
              <option value="Print All">Print All</option>
            </select>
          </div>
        </div>
        {/* <hr className="mr-30 my-10"></hr>
        <div className="d-flex jc-between my-10">
          <div
            style={{
              visibility: "hidden",
            }}
          >
            <button type="button" className="button button-brown mr-30">
              View grid
            </button>
            <button type="button" className="button button-brown mr-30">
              Save
            </button>
            <button className="btn-undo" type="button">
              <svg className="icon fs-30 pt-10">
                <use href="#icon_undo"></use>
              </svg>
            </button>
          </div>
          <div>
            <button
              type="button"
              className="button button-brown mr-30"
              // onClick={handleShowAddCred}
            >
              Export
            </button>
          </div>
        </div> */}
        <div ref={componentRef} className="tbl-scroller">
          {!isGettingRows && rows?.length ? (
            <table className="table-wrapper">
              <thead>
                <tr>
                  <th className="srtd">Sr.No.</th>
                  {columns.map((column) => (
                    <th key={column.code}>{column.name}</th>
                  ))}
                  {/* {isHo() && <th>Action</th>} */}
                </tr>
              </thead>
              <tbody className="tbody-gap">
                <tr>
                  <td colSpan="13"></td>
                </tr>
              </tbody>

              <tbody>
                {rows.map((row, index) => (
                  <tr key={row?.id}>
                    {/* <td>{index + 1}</td> */}
                    <td>{parseInt(page) * limit + index + 1}</td>
                    {columns.map((column) => (
                      <>
                        {column.code !== "balance_amount" && (
                          <td key={column.code}>
                            {column.format
                              ? column.format(row[column.code])
                              : row[column.code]}
                          </td>
                        )}
                        {column.code === "balance_amount" && (
                          <td key={column.code}>
                            <div className="d-flex align-items-center justify-content-center">
                              <div>
                                {parseFloat(
                                  column.format(row["due_amount"]) || 0
                                ) -
                                  parseFloat(
                                    column.format(row["paid_amount"]) || 0
                                  )}
                              </div>
                              {/* <div>
                                {(
                                  parseFloat(column.format(row["due_amount"]) || 0) -
                                  parseFloat(column.format(row["paid_amount"]) || 0)) > 0 &&
                                  <button type="button" className="button button-brown py-1 ms-2">Paid</button>
                                }
                              </div> */}
                            </div>
                          </td>
                        )}
                      </>
                    ))}
                    {/* {isHo() && (
                      <td>
                        <button
                          className="button  px-2"
                          type="button"
                          onClick={() => {
                            handleShowAddCred(row);
                          }}
                        >
                          <svg className="icon fs-22">
                            <use href="#icon_edit"></use>
                          </svg>
                        </button>
                        <button
                          className="button  px-2"
                          type="button"
                          onClick={() => {
                            setShowDeleteModal(row);
                          }}
                        >
                          <svg className="icon fs-22 text-danger">
                            <use href="#icon_delete"></use>
                          </svg>
                        </button>
                      </td>
                    )} */}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="d-flex jc-center">
              <span>No data found</span>
            </div>
          )}
          {isGettingRows && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader color={"#aa741f"} />
            </div>
          )}
          {/* {!rows.length && !isGettingRows && (
            <div className="d-flex jc-center">
              <span>No data found</span>
            </div>
          )} */}
        </div>

        <form className="d-flex jc-between pt-30">
          <select
            value={limit}
            onChange={(e) => {
              setLimit(e.target.value);
              setPage(0);
            }}
            name="cars"
            id="cars"
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
            <option value={60}>60</option>
            <option value={70}>70</option>
            <option value={90}>90</option>
            <option value={100}>100</option>
          </select>
          <div className="pagination">
            <button
              disabled={parseInt(page) === 0}
              onClick={() => setPage(parseInt(page) - 1)}
              type="button"
              className="page-leftright"
            >
              <svg className="icon fs-24">
                <use href="#icon_leftarrow"></use>
              </svg>
            </button>
            <span>
              Page {parseInt(page) + 1} of {Math.ceil(totalCount / limit)}
            </span>
            <button
              disabled={parseInt(page) + 1 === Math.ceil(totalCount / limit)}
              onClick={() => setPage(parseInt(page) + 1)}
              type="button"
              className="page-leftright"
            >
              <svg className="icon fs-24">
                <use href="#icon_rightarrow"></use>
              </svg>
            </button>
            <button
              disabled={parseInt(page) + 1 === Math.ceil(totalCount / limit)}
              onClick={() => setPage(Math.ceil(totalCount / limit) - 1)}
              type="button"
              className="page-leftright"
            >
              <svg className="icon fs-24">
                <use href="#icon_roundArrow"></use>
              </svg>
            </button>
          </div>
        </form>
      </div>
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
      <SuccessModal
        showSuccess={showSuccess}
        successMessagePopup={successMessagePopup}
        handleCloseSuccess={handleCloseSuccess}
      />
      <AddEditVehicleChallan
        showAddCred={showAddCred}
        handleCloseAddCred={handleCloseAddCred}
        handleAddEditMobileCreds={handleAddEditMobileCreds}
        isUpdatingCreds={isUpdatingCreds}
        label={"Vehicle Challan"}
        articlesList={articlesList}
        userList={vehicleList}
        selectedUserParent={selectedUser}
        selectedArticleParent={selectedArticle}
      />
      <Modal
        show={showDeleteModal}
        centered
        onHide={() => {
          setShowDeleteModal(false);
        }}
        className="tracking-modal"
      >
        <Modal.Body className="p-4 text-center">
          <h3>Are you sure you want to delete this record?</h3>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="button "
            onClick={() => {
              setShowDeleteModal(false);
            }}
          >
            No
          </button>
          <button
            className="button button-brown"
            onClick={() => {
              handleDelete(showDeleteModal);
            }}
            disabled={isDeleting}
          >
            {isDeleting ? <Loader /> : "Yes"}
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showFetchConfirmation}
        centered
        onHide={() => setShowFetchConfirmation(false)}
        className="tracking-modal"
      >
        <Modal.Body className="p-4 text-center">
          <h3>
            Are you sure you want to fetch challan for {selectedVehicle?.label}?
          </h3>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="button"
            onClick={() => setShowFetchConfirmation(false)}
          >
            No
          </button>
          <button
            className="button button-brown"
            onClick={handleFetchChallanAPI}
            disabled={isFetchingVehicle}
          >
            {isFetchingVehicle ? <Loader /> : "Yes"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default VehicleChallan;
