import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import axios from "axios";
import { useNavigate, useOutletContext } from "react-router-dom";
import Loader from "./Loader";
import Select from "react-select";

function AddEditVehicleService({
    showAddCred,
    handleCloseAddCred,
    handleAddEditMobileCreds,
    isUpdatingCreds,
    label,
    articlesList,
    userList,
    selectedArticleParent,
    selectedUserParent
}) {
    // "trans_date":"2024-10-10",
    //   "payment_type":"1",
    //   "from_code":"E001",
    //   "to_code":"V001",
    //   "amount":"1000"

    const navigate = useNavigate();
    const [date, setDate] = useState("");
    const [vehicleCode, setvehicleCode] = useState("");
    const [dueOnKM, setDueOnKM] = useState("");
    const [doneDate, setDoneDate] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [amountPaid, setAmountPaid] = useState("");
    const [serviceOnKM, setServiceOnKM] = useState("");
    const [servicePlace, setServicePlace] = useState("");
    const [serviceTakenBy, setServiceTakenBy] = useState("");
    const [majorThingsDone, setMajorThingsDone] = useState("");
    const [remarks, setRemarks] = useState("");

    const typeOptions = [
        { value: 1, label: "Quantity" },
        { value: 2, label: "Total Amount" },
    ];

    const handleClose = () => {
        setDate("");
        setvehicleCode("");
        setDueOnKM("");
        setDoneDate("");
        setDueDate("");
        setAmountPaid("");
        setServiceOnKM("");
        setServicePlace("");
        setServiceTakenBy("");
        setMajorThingsDone("");
        setRemarks("");
        handleCloseAddCred();
    };

    async function getContractors(type) {
        try {
            const token = localStorage.getItem("him_user_token");

            const response = await axios({
                url: `${process.env.REACT_APP_BASE_URL}/himreport/vehicalserviceupdate`,
                method: "GET",
                params: {
                    ledger_master: type || "",
                },
                headers: {
                    jwt_token: token,
                },
            });

            if (response.status === 200) {
                // setUserList(
                return response.data?.data || [];
                // [
                //   // {
                //   //   code: "E001",
                //   //   name: "E001",
                //   //   id: 1,
                //   // },
                //   // {
                //   //   code: "V001",
                //   //   name: "V001",
                //   //   id: 2,
                //   // },
                // ]
                // );
            } else {
                return [];
            }
        } catch (error) {
            if (error?.response?.status == 403) {
                localStorage.removeItem("him_user_token");
                localStorage.removeItem("him_user_data");
                navigate("/login");
                return [];
            }

            if (error?.code === "ECONNABORTED") {
                console.log("Request timed out");
            } else {
                console.log("An error occurred:", error);
            }
        }
    }

    useEffect(() => {
        if (showAddCred?.id) {
            // let selected_article = articlesListusers.find(
            //     (item) => item.code === showAddCred?.location
            // );
            setDate(showAddCred?.date_off_bill_raised);
            setvehicleCode(showAddCred?.vehicle_code);
            setDueDate(showAddCred?.due_date);
            setDueOnKM(showAddCred?.due_on_km);
            setDoneDate(showAddCred?.done_date);
            setAmountPaid(showAddCred?.amount_paid);
            setServiceOnKM(showAddCred?.service_on_km);
            setServicePlace(showAddCred?.service_place);
            setServiceTakenBy(showAddCred?.service_taken_by);
            setMajorThingsDone(showAddCred?.major_things_done);
            setRemarks(showAddCred?.remarks);
        }
    }, [showAddCred]);
    console.log(showAddCred, "showadd cred")

    // useEffect(() => {
    //     if (type?.value == 1) {
    //         const calculatedTotal =
    //             parseFloat(quantity) * parseFloat(amountPerQuantity);
    //         setTotal(calculatedTotal);
    //     }
    // }, [quantity, amountPerQuantity]);

    return (
        <Modal show={showAddCred} onHide={handleClose} animation={true} scrollable={true} centered>
            <Modal.Header closeButton>
                <Modal.Title>{label}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form
                    onSubmit={(e) =>
                        handleAddEditMobileCreds(
                            e,
                            showAddCred?.id || null,
                            date,
                            vehicleCode,
                            dueOnKM,
                            doneDate,
                            dueDate,
                            amountPaid,
                            serviceOnKM,
                            servicePlace,
                            serviceTakenBy,
                            majorThingsDone,
                            remarks,
                            handleClose
                        )
                    }
                >
                    <div className="mb-2">
                        <label htmlFor="" className="mb-10 d-block gray">
                            Vehicle Code
                        </label>
                        <input
                            required
                            value={vehicleCode}
                            onChange={(e) => setvehicleCode(e.target.value)}
                            type="text"
                            className="input-box"
                            placeholder="Enter Vehicle Code"
                        />
                    </div>

                    <div className="mb-2">
                        <label htmlFor="" className="mb-10 d-block gray">
                            Due Date
                        </label>
                        <input
                            required
                            value={dueDate}
                            onChange={(e) => setDueDate(e.target.value)}
                            type="date"
                            className="input-box"
                            placeholder="Select Due Date"
                        />
                    </div>

                    <div className="mb-2">
                        <label htmlFor="" className="mb-10 d-block gray">
                            Due On KM
                        </label>
                        <input
                            required
                            value={dueOnKM}
                            onChange={(e) => setDueOnKM(e.target.value)}
                            type="text"
                            className="input-box"
                            placeholder="Select Challan Date"
                            onFocus={(e) => e.target.showPicker()}
                        />
                    </div>

                    <div className="mb-2">
                        <label htmlFor="" className="mb-10 d-block gray">
                            Done Date
                        </label>
                        <input
                            required
                            value={doneDate}
                            onChange={(e) => setDoneDate(e.target.value)}
                            type="date"
                            className="input-box"
                            placeholder="Select Done Date"
                        />
                    </div>

                    <div className="mb-2">
                        <label htmlFor="" className="mb-10 d-block gray">
                            Amount Paid
                        </label>
                        <input
                            required
                            value={amountPaid}
                            onChange={(e) => setAmountPaid(e.target.value)}
                            type="text"
                            className="input-box"
                            placeholder="Enter Amount Paid"
                        />
                    </div>

                    <div className="mb-2">
                        <label htmlFor="" className="mb-10 d-block gray">
                            Service On KM
                        </label>
                        <input
                            required
                            value={serviceOnKM}
                            onChange={(e) => setServiceOnKM(e.target.value)}
                            type="text"
                            className="input-box"
                            placeholder="Enter Service On KM"
                        />
                    </div>

                    {/* <div className="mb-2">
                        <label htmlFor="" className="mb-10 d-block gray">
                            Article
                        </label>
                        <Select
                            value={selectedArticle}
                            required
                            onChange={(value) => setSelectedArticle(value)}
                            options={articlesList.map((user) => ({
                                value: user?.item_name,
                                label: user?.item_name,
                            }))}
                            placeholder="Select Type"
                            isSearchable
                            isClearable
                        />
                    </div> */}

                    <div className="mb-2">
                        <label htmlFor="" className="mb-10 d-block gray">
                            Service Place
                        </label>
                        <input
                            required
                            value={servicePlace}
                            onChange={(e) => setServicePlace(e.target.value)}
                            type="text"
                            className="input-box"
                            placeholder="Enter Service Place"
                        />
                    </div>

                    <div className="mb-2">
                        <label htmlFor="" className="mb-10 d-block gray">
                            Service Taken By
                        </label>
                        <input
                            required
                            value={serviceTakenBy}
                            onChange={(e) => setServiceTakenBy(e.target.value)}
                            type="text"
                            className="input-box"
                            placeholder="Enter Service Taken By"
                        />
                    </div>

                    <div className="mb-2">
                        <label htmlFor="" className="mb-10 d-block gray">
                            Major Things Done
                        </label>
                        <input
                            required
                            value={majorThingsDone}
                            onChange={(e) => setMajorThingsDone(e.target.value)}
                            type="text"
                            className="input-box"
                            placeholder="Enter Major Things Done"
                        />
                    </div>

                    <div className="mb-2">
                        <label htmlFor="" className="mb-10 d-block gray">
                            Remarks
                        </label>
                        {/* textarea */}
                        <textarea
                            value={remarks}
                            onChange={(e) => setRemarks(e.target.value)}
                            className="input-box"
                            placeholder="Enter Remarks"
                        ></textarea>
                    </div>

                    {/* <div className="mb-2">
                        <label htmlFor="" className="mb-10 d-block gray">
                            Choose user
                        </label>
                        <Select
                            value={selectedUser}
                            required
                            onChange={(value) => setselectedUser(value)}
                            options={userList.map((user) => ({
                                value: user?.code,
                                label: user?.code, //+ "-" + user?.name,
                            }))}
                            placeholder="Select Type"
                            isSearchable
                            isClearable
                        />
                    </div> */}

                    <div className="mb-2 mt-4">
                        <button
                            disabled={isUpdatingCreds}
                            type="submit"
                            className="button button-brown w-100"
                        >
                            {isUpdatingCreds ? <Loader /> : "Save"}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default AddEditVehicleService;
