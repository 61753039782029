import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import UploadFile from "../components/UploadFile";
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AddMobileCredentials from "../components/AddMobileCredentials";
import Loader from "../components/Loader";
import moment from "moment";
import AddHeadOfficeTransaction from "../components/AddHeadOfficeTransaction";
import { DatePicker } from "antd";
import { useReactToPrint } from "react-to-print";
const { RangePicker } = DatePicker;
function HeadOfficeCashReceiving() {
  const [show, setShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessagePopup, setSuccessMessagePopup] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(40);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(true);
  const [isGettingRows, setIsGettingRows] = useState(false);
  const [isUpdatingCreds, setIsUpdatingCreds] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [walletBalence, setWalletBalence] = useState(0);

  const handleClose = () => {
    setShow(false);
    setSelectedFile(null);
  };
  const handleShow = () => setShow(true);

  const [showAddCred, setShowAddCred] = useState(false);

  const handleCloseAddCred = () => setShowAddCred(false);
  const handleShowAddCred = () => setShowAddCred(true);

  const navigate = useNavigate();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    pageStyle: `
    @page {
      size: A4 landscape;
    }
    @media print {
      body {
        -webkit-print-color-adjust: exact;
      }
      .tbl-scroller {
        overflow: visible !important;
      }
      .table-wrapper {
        width: 100%;
        transform: scale(0.75);
        transform-origin: top left;
      }
      * {
        color: black !important;
      }
    }
  `,
  });
  function handleCloseSuccess() {
    setshowSuccess(false);
    setSuccessMessagePopup("");
  }

  async function handleFileUpload(event) {
    // event && event?.preventDefault();
    if (!selectedFile) {
      setError({ title: "Error", err: "Please select a file to upload" });
      setShowErrModal(true);
      return;
    }
    setIsUploading(true);
    try {
      const token = localStorage.getItem("him_user_token");
      const formData = new FormData();
      // rawFile?.forEach((file) => {
      formData.append("document", selectedFile);
      // });

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/bulkuploadreceipt`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: formData,
      });

      if (response?.data?.success) {
        setshowSuccess(true);
        setSuccessMessagePopup(response.data?.msg);
        handleClose();
        setTimeout(() => {
          !response?.data?.errorItems?.length && handleCloseSuccess();
        }, 5000);
      } else {
        setError({ title: "Error", err: response?.data?.msg });
        setShowErrModal(true);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsUploading(false);
      // getRows();
      setPage("0");
    }
  }

  async function getRows(selectedFromDate, selectedToDate) {
    try {
      setIsGettingRows(true);
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/headofficetransaction`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: {
          from_date: selectedFromDate
            ? selectedFromDate?.format("YYYY-MM-DD")
            : null,
          to_date: selectedToDate ? selectedToDate?.format("YYYY-MM-DD") : null,
          payment_type: 2,
          page: parseInt(page),
          limit: parseInt(limit),
          paginate: true,
        },
      });

      setRows(response?.data?.data);
      setTotalCount(response?.data?.count);
      setWalletBalence(response?.data?.wallet_bal || 0);
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });

      if (error?.response?.status == 403) {
        console.log("error", error);
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsGettingRows(false);
    }
  }

  async function handleAddEditMobileCreds(
    event,
    date,
    paymentType,
    fromCode,
    toCode,
    amount,
    handleCloseCallback,
    recBy
  ) {
    event && event?.preventDefault();

    setIsUpdatingCreds(true);
    try {
      const token = localStorage.getItem("him_user_token");

      const data = {
        trans_date: moment(date).format("YYYY-MM-DD"),
        payment_type: paymentType,
        from_code: fromCode,
        to_code: toCode || null,
        amount: amount,
        cash_rec_by: recBy || null,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/updatedailytransaction`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: data,
      });

      if (response?.data?.success) {
        setshowSuccess(true);
        setSuccessMessagePopup(response.data?.msg);
        setTimeout(() => {
          handleCloseSuccess();
        }, 5000);
        handleCloseCallback ? handleCloseCallback() : handleCloseAddCred();
      } else {
        setError({ title: "Error", err: response?.data?.msg });
        setShowErrModal(true);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsUpdatingCreds(false);
      // getRows();
      setPage("0");
    }
  }
  useEffect(() => {
    getRows(selectedFromDate, selectedToDate);
  }, [page, limit]);
  return (
    <>
      <div className="inner-box">
        <div className="d-flex">
          <h1 className="heading">Head Office Cash Receiving</h1>
          {/* <div className="ms-auto">
            <button
              className="button button-brown px-4"
              type="button"
              onClick={handleShow}
            >
              Upload
            </button>
          </div> */}
        </div>

        {/* new add */}
        <div className="mb-20 d-flex gray justify-content-between">
          <div className="d-flex">
            {/* <div className="w-45 mr-30">
              <label htmlFor="" className="mb-10 d-block">
                From Date
              </label>
              <input
                value={selectedFromDate}
                onChange={(e) => setSelectedFromDate(e.target.value)}
                type="date"
                className="input-box gray"
              />
            </div>
            <div className="w-45 mr-30">
              <label htmlFor="" className="mb-10 d-block">
                To Date
              </label>
              <input
                value={selectedToDate}
                onChange={(e) => setSelectedToDate(e.target.value)}
                type="date"
                className="input-box gray"
              />
            </div> */}
            <div className="w-45 mr-30">
              <label htmlFor="" className="mb-10 d-block">
                Date
              </label>
              <RangePicker
                className="input-box gray"
                value={[selectedFromDate, selectedToDate]}
                format={"DD MMM YYYY"}
                onChange={(dates) => {
                  setSelectedFromDate(dates?.length ? dates[0] : "");
                  setSelectedToDate(dates?.length ? dates[1] : "");
                  if (page == 0) {
                    getRows(
                      dates?.length ? dates[0] : "",
                      dates?.length ? dates[1] : ""
                    );
                  } else {
                    setPage(0);
                  }
                }}
              />
            </div>
          </div>

          <div
            style={{
              alignSelf: "flex-end",
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            <button
              onClick={handleShowAddCred}
              type="button"
              className="button button-brown mr-20"
            >
              Add New
            </button>
            <button
              className="button button-brown px-4 me-4"
              type="button"
              disabled={isDownloading}
              onClick={() => {
                setIsDownloading(true);
                const link = document.createElement("a");
                link.href = "/cash_recieving_head_office.xlsx";
                link.download = "cash_recieving_head_office.xlsx";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setIsDownloading(false);
              }}
            >
              {isDownloading ? <Loader /> : "Download Sample"}
            </button>
            <button
              className="button button-brown "
              type="button"
              onClick={handleShow}
            >
              Bulk Upload
            </button>
            <button
              type="button"
              className="button button-brown mr-30"
              onClick={handlePrint}
              disabled={!rows.length}
            >
              Print
            </button>
          </div>
        </div>
        <hr className="mr-30 my-10"></hr>
        <div className="d-flex jc-between my-10">
          <div>
            <h3 className="fs-20">Wallet Balance: {walletBalence}</h3>
          </div>
          {/* <div>
            <button type="button" className="button button-brown mr-30">
              View grid
            </button>
            <button type="button" className="button button-brown mr-30">
              Save
            </button>
            <button className="btn-undo" type="button">
              <svg className="icon fs-30 pt-10">
                <use href="#icon_undo"></use>
              </svg>
            </button>
          </div>
          <div>
            <button
              type="button"
              className="button button-brown mr-30"
              onClick={handleShowAddCred}
            >
              Export
            </button>
          </div> */}
        </div>
        <div ref={componentRef} className="tbl-scroller">
          <table className="table-wrapper">
            <thead>
              <tr>
                <th className="srtd"> Sr. No. </th>
                <th> Received Date </th>
                <th> Amount Received From </th>
                <th> Amount</th>
                <th> Received By</th>
              </tr>
            </thead>
            <tbody className="tbody-gap">
              <tr>
                <td colSpan="13"></td>
              </tr>
            </tbody>

            {!isGettingRows && (
              <tbody>
                {rows.map((row, index) => (
                  <tr key={row?.id}>
                    {/* <td>{index + 1}</td> */}
                    <td>{parseInt(page) * limit + index + 1}</td>
                    <td>
                      {row?.date ? moment(row?.date).format("DD MMM YYYY") : ""}
                    </td>
                    <td>{row?.from_code}</td>
                    <td>{row?.credit_amount}</td>
                    <td>{row?.received_by_data?.code}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {isGettingRows && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader color={"#aa741f"} />
            </div>
          )}
          {!rows.length && !isGettingRows && (
            <div className="d-flex jc-center">
              <span>No data found</span>
            </div>
          )}
        </div>

        <form className="d-flex jc-between pt-30">
          <select
            value={limit}
            onChange={(e) => {
              setLimit(e.target.value);
              setPage(0);
            }}
            name="cars"
            id="cars"
          >
            <option value={2}>2</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
            <option value={60}>60</option>
            <option value={70}>70</option>
            <option value={90}>90</option>
            <option value={100}>100</option>
          </select>
          <div className="pagination">
            <button
              disabled={parseInt(page) === 0}
              onClick={() => setPage(parseInt(page) - 1)}
              type="button"
              className="page-leftright"
            >
              <svg className="icon fs-24">
                <use href="#icon_leftarrow"></use>
              </svg>
            </button>
            <span>
              Page {parseInt(page) + 1} of {Math.ceil(totalCount / limit)}
            </span>
            <button
              disabled={parseInt(page) + 1 === Math.ceil(totalCount / limit)}
              onClick={() => setPage(parseInt(page) + 1)}
              type="button"
              className="page-leftright"
            >
              <svg className="icon fs-24">
                <use href="#icon_rightarrow"></use>
              </svg>
            </button>
            <button
              disabled={parseInt(page) + 1 === Math.ceil(totalCount / limit)}
              onClick={() => setPage(Math.ceil(totalCount / limit) - 1)}
              type="button"
              className="page-leftright"
            >
              <svg className="icon fs-24">
                <use href="#icon_roundArrow"></use>
              </svg>
            </button>
          </div>
        </form>
      </div>

      <UploadFile
        modalTitle={"Upload Cash Receiving"}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        show={show}
        setShow={setShow}
        onUpload={handleFileUpload}
        isUploading={isUploading}
      />
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
      <SuccessModal
        showSuccess={showSuccess}
        successMessagePopup={successMessagePopup}
        handleCloseSuccess={handleCloseSuccess}
      />
      <AddHeadOfficeTransaction
        showAddCred={showAddCred}
        handleCloseAddCred={handleCloseAddCred}
        handleAddEditMobileCreds={handleAddEditMobileCreds}
        isUpdatingCreds={isUpdatingCreds}
        label={"Add Head Office Cash Receiving"}
        paymentType={2}
      />
    </>
  );
}

export default HeadOfficeCashReceiving;
