import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import axios from "axios";
import { useNavigate, useOutletContext } from "react-router-dom";
import Loader from "./Loader";
import Select from "react-select";

function AddEditHardCopyBillReceiving({
  showAddCred,
  handleCloseAddCred,
  handleAddEditMobileCreds,
  isUpdatingCreds,
  label,
  articlesList,
  userList,
  selectedArticleParent,
  selectedUserParent,
}) {
  // "trans_date":"2024-10-10",
  //   "payment_type":"1",
  //   "from_code":"E001",
  //   "to_code":"V001",
  //   "amount":"1000"

  const navigate = useNavigate();
  const [date, setDate] = useState("");

  // const [fromCode, setFromCode] = useState("");
  const [fromVendor, setfromVendor] = useState("");
  // const [toFirm, setToCode] = useState("");
  const [toFirm, settoFirm] = useState("");
  const [billAmount, setbillAmount] = useState("");
  const [invoiceNo, setinvoiceNo] = useState("");
  const [amountPerQuantity, setAmountPerQuantity] = useState("");
  const [via, setVia] = useState("");
  const [type, setType] = useState();
  const [selectedArticle, setSelectedArticle] = useState("");
  const [total, setTotal] = useState("");
  const [quantity, setQuantity] = useState("");

  const [gstRatio, setgstRatio] = useState("");
  const [purpose_of_payment, setpurpose_of_payment] = useState("");
  const [pendingAmountInvoice, setpendingAmountInvoice] = useState("");
  const [vendorContact, setvendorContact] = useState("");
  const [remarks, setRemarks] = useState("");
  const [selectedUser, setselectedUser] = useState("");
  const [firmList, setFirmList] = useState([]);
  const [ledgerList, setLedgerList] = useState([]);
  const [vendorContactList, setVendorContactList] = useState([]);
  const [vendorList, setVendorList] = useState([]);

  const typeOptions = [
    { value: 1, label: "Quantity" },
    { value: 2, label: "Total Amount" },
  ];

  const handleClose = () => {
    setDate("");

    setfromVendor("");
    settoFirm("");
    setbillAmount("");
    setinvoiceNo("");
    setgstRatio("");
    setpurpose_of_payment("");
    setpendingAmountInvoice("");
    setvendorContact("");
    setRemarks("");
    setSelectedArticle("");
    setselectedUser("");
    handleCloseAddCred();
  };

  async function getContractors(type) {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/updatehardcopybill`,
        method: "GET",
        params: {
          ledger_master: type || "",
        },
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        // setUserList(
        return response.data?.data || [];
        // [
        //   // {
        //   //   code: "E001",
        //   //   name: "E001",
        //   //   id: 1,
        //   // },
        //   // {
        //   //   code: "V001",
        //   //   name: "V001",
        //   //   id: 2,
        //   // },
        // ]
        // );
      } else {
        return [];
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return [];
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }
  async function getFirmList() {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/vendorcontractorlist`,
        method: "GET",
        params: {
          ledger_master: "Firm",
        },
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        setFirmList(response.data?.data);
      } else {
        setFirmList([]);
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }
  async function getLedgerList() {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/payment_purpose`,
        method: "GET",
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        return setLedgerList(response.data?.data || []);
      } else {
        return setLedgerList([]);
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }
  async function getVendorContact() {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/contactperson`,
        method: "GET",

        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        return setVendorContactList(response.data?.data || []);
      } else {
        return setVendorContactList([]);
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }
  async function getAllEmployees() {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/vendorcontractorlist`,
        method: "GET",
        params: {
          ledger_master: "Vendor",
        },
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        setVendorList(response.data?.data);
      } else {
        setVendorList([]);
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }

  useEffect(() => {
    if (showAddCred?.id) {
      let selected_article = articlesList.find(
        (item) => item.item_name === showAddCred?.article
      );
      console.log(selected_article, "selected_article");
      setSelectedArticle(
        selected_article
          ? {
              value: selected_article?.item_name,
              label: selected_article?.item_name,
            }
          : null
      );
      setDate(showAddCred?.date_off_bill_raised);

      setfromVendor(
        showAddCred?.from_vendor
          ? {
              value: showAddCred?.from_vendor,
              label: showAddCred?.from_vendor,
            }
          : null
      );
      settoFirm(
        showAddCred?.to_firm
          ? {
              value: showAddCred?.to_firm,
              label: showAddCred?.to_firm,
            }
          : null
      );
      setbillAmount(showAddCred?.amount);
      setinvoiceNo(showAddCred?.invoice_number);
      setgstRatio(showAddCred?.gst_ratio);
      setpurpose_of_payment(
        showAddCred?.ledger_head
          ? {
              value: showAddCred?.ledger_head,
              label: showAddCred?.ledger_head,
            }
          : null
      );
      setpendingAmountInvoice(showAddCred?.pending_amount_invoices);
      setvendorContact(
        showAddCred?.vendor_contact_person
          ? {
              value: showAddCred?.vendor_contact_person,
              label: showAddCred?.vendor_contact_person,
            }
          : null
      );
      setRemarks(showAddCred?.remarks);
    }
    getFirmList();
    getLedgerList();
    getVendorContact();
    getAllEmployees();
  }, [showAddCred]);
  console.log(showAddCred, "showadd cred", selectedArticle);

  useEffect(() => {
    if (type?.value == 1) {
      const calculatedTotal =
        parseFloat(quantity) * parseFloat(amountPerQuantity);
      setTotal(calculatedTotal);
    }
  }, [quantity, amountPerQuantity]);

  return (
    <Modal
      show={showAddCred}
      onHide={handleClose}
      animation={true}
      scrollable={true}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={(e) =>
            handleAddEditMobileCreds(
              e,
              showAddCred?.id || null,
              date,
              fromVendor?.value,
              toFirm?.value,
              billAmount,
              invoiceNo,
              selectedArticle?.value,
              gstRatio,
              purpose_of_payment?.value,
              pendingAmountInvoice,
              vendorContact?.value,
              remarks,
              handleClose
            )
          }
        >
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Date Of Bill Raised
            </label>
            <input
              required
              value={date}
              onChange={(e) => setDate(e.target.value)}
              type="date"
              className="input-box"
              placeholder="Select Date"
              onFocus={(e) => e.target.showPicker()}
            />
          </div>

          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              From Vendor
            </label>
            {/* <input
                            required
                            value={fromVendor}
                            onChange={(e) => setfromVendor(e.target.value)}
                            type="text"
                            className="input-box"
                            placeholder="Enter From vendor"
                        /> */}
            <Select
              value={fromVendor}
              required
              onChange={(value) => setfromVendor(value)}
              options={vendorList.map((user) => ({
                value: user?.code,
                label: user?.code, // + "-" + user?.name,
              }))}
              placeholder="Select Type"
              isSearchable
              isClearable
            />
          </div>

          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              To Firm
            </label>
            {/* <input
                            required
                            value={toFirm}
                            onChange={(e) => settoFirm(e.target.value)}
                            type="text"
                            className="input-box"
                            placeholder="Enter To Firm"
                        /> */}
            <Select
              value={toFirm}
              required
              onChange={(value) => settoFirm(value)}
              options={firmList.map((firm) => ({
                value: firm?.code,
                label: firm?.code,
              }))}
              placeholder="Select Type"
              isSearchable
              isClearable
            />
          </div>

          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Bill Amount
            </label>
            <input
              required
              value={billAmount}
              onChange={(e) => setbillAmount(e.target.value)}
              type="text"
              className="input-box"
              placeholder="Enter Bill Amount"
            />
          </div>
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Invoice No
            </label>
            <input
              required
              value={invoiceNo}
              onChange={(e) => setinvoiceNo(e.target.value)}
              type="text"
              className="input-box"
              placeholder="Enter Bill Amount"
            />
          </div>

          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Article
            </label>
            <Select
              value={selectedArticle}
              required
              onChange={(value) => setSelectedArticle(value)}
              options={articlesList.map((user) => ({
                value: user?.item_name,
                label: user?.item_name,
              }))}
              placeholder="Select Type"
              isSearchable
              isClearable
            />
          </div>
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              GST Ratio
            </label>
            <input
              required
              value={gstRatio}
              onChange={(e) => setgstRatio(e.target.value)}
              type="text"
              className="input-box"
              placeholder="Enter Article"
            />
          </div>
          {/* <div className="mb-2">
                        <label htmlFor="" className="mb-10 d-block gray">
                            Purpose of payment
                        </label>
                        <Select
                            value={purpose_of_payment}
                            required
                            onChange={(value) => setpurpose_of_payment(value)}
                            options={ledgerList.map((user) => ({
                                value: user?.name,
                                label: user?.name, //+ "-" + user?.name,
                            }))}
                            placeholder="Select Type"
                            isSearchable
                            isClearable
                        />
                    </div>
                    <div className="mb-2">
                        <label htmlFor="" className="mb-10 d-block gray">
                            Pending Amount Invoices
                        </label>
                        <input
                            required
                            value={pendingAmountInvoice}
                            onChange={(e) => setpendingAmountInvoice(e.target.value)}
                            type="text"
                            className="input-box"
                            placeholder="Enter Pending Amount"
                        />
                    </div>
                    <div className="mb-2">
                        <label htmlFor="" className="mb-10 d-block gray">
                            Vendor Contact
                        </label>
                        <Select
                            value={vendorContact}
                            required
                            onChange={(value) => setvendorContact(value)}
                            options={vendorContactList.map((user) => ({
                                value: user?.vendor_contact,
                                label: user?.vendor_contact, //+ "-" + user?.name,
                            }))}
                            placeholder="Select Type"
                            isSearchable
                            isClearable
                        />
                    </div> */}
          {/* <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Remarks
            </label>
            
            <textarea
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              className="input-box"
              placeholder="Enter Remarks"
            ></textarea>
          </div> */}
          {/* <div className="mb-2">
                        <label htmlFor="" className="mb-10 d-block gray">
                            Choose user
                        </label>
                        <Select
                            value={selectedUser}
                            required
                            onChange={(value) => setselectedUser(value)}
                            options={userList.map((user) => ({
                                value: user?.code,
                                label: user?.code, //+ "-" + user?.name,
                            }))}
                            placeholder="Select Type"
                            isSearchable
                            isClearable
                        />
                    </div> */}

          <div className="mb-2 mt-4">
            <button
              disabled={isUpdatingCreds}
              type="submit"
              className="button button-brown w-100"
            >
              {isUpdatingCreds ? <Loader /> : "Save"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddEditHardCopyBillReceiving;
