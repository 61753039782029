import axios from "axios";
import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Offcanvas from "react-bootstrap/Offcanvas";
import Select from "react-select";
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Loader from "../components/Loader";
import { DatePicker } from "antd";
import * as XLSX from "xlsx";
const { RangePicker } = DatePicker;
function VendorQuotesApproved() {
  const navigate = useNavigate();
  //   const [show, setShow] = useState(false);
  //   aside
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessagePopup, setSuccessMessagePopup] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(40);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isGettingRows, setIsGettingRows] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [selectedSite, setSelectedSite] = useState();
  const [selectedVendor, setSelectedVendor] = useState();
  const [quoteType, setQuoteType] = useState("measurements");
  const [selectedArticle, setSelectedArticle] = useState();
  const [articlesList, setArticlesList] = useState([]);

  function handleCloseSuccess() {
    setshowSuccess(false);
    setSuccessMessagePopup("");
  }
  //   search select

  const [optionsFirm, setOptionsFirm] = useState([]);
  const [optionsVendor, setOptionsVendor] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [displayModal, setDisplayModal] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const handleShowItems = (row) => {
    setDisplayModal(row);
  };
  const componentRef = useRef();

  async function getRows(
    selectedFromDate,
    selectedToDate,
    selectedSite,
    selectedVendor,
    quoteType,
    selectedArticle
  ) {
    try {
      setIsGettingRows(true);
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getvendorquoteitem`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: {
          from_date: selectedFromDate
            ? selectedFromDate?.format("YYYY-MM-DD")
            : null,
          to_date: selectedToDate ? selectedToDate?.format("YYYY-MM-DD") : null,
          site_code: selectedSite?.value,
          vendor_code: selectedVendor?.value,
          item_name: selectedArticle?.value,
          // quote_type: quoteType,
          page: parseInt(page),
          limit: parseInt(limit),
          paginate: true,
          // approve: true,
          status: 2,
        },
      });

      setRows(response?.data?.data);
      setTotalCount(response?.data?.count);
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });

      if (error?.response?.status == 403) {
        console.log("error", error);
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsGettingRows(false);
    }
  }

  async function printOrExportData(
    isPrint,
    selectedFromDate,
    selectedToDate,
    selectedSite,
    selectedVendor,
    quoteType,
    selectedArticle,
    rows
  ) {
    try {
      if (isPrint) {
        setIsPrinting(true);
      } else {
        setIsExporting(true);
      }
      const token = localStorage.getItem("him_user_token");
      let tableData = [];
      if (isPrint && rows?.length) {
        tableData = rows;
      } else {
        const response = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/himreport/getvendorquote`,
          method: "POST",
          headers: {
            jwt_token: token,
          },
          data: {
            from_date: selectedFromDate
              ? selectedFromDate?.format("YYYY-MM-DD")
              : null,
            to_date: selectedToDate
              ? selectedToDate?.format("YYYY-MM-DD")
              : null,
            site_code: selectedSite?.value,
            vendor_code: selectedVendor?.value,
            item_name: selectedArticle?.value,
            // quote_type: quoteType,
            paginate: false,
            // approve: true,
            status: 2,
          },
        });

        tableData = response?.data?.data || [];
      }
      if (isPrint) {
        // Open a new window
        const printWindow = window.open("", "");

        // Generate HTML content for the new window
        const tableHtml = `
          <html>
            <head>
              <title>Print</title>
              <style>
                body {
                  font-family: Arial, sans-serif;
                }
                .table-wrapper {
                  width: 100%;
                  border-collapse: collapse;
                }
                .table-wrapper th, .table-wrapper td {
                  border: 1px solid #000;
                  padding: 8px;
                  text-align: left;
                }
                .table-wrapper th {
                  background-color: #f2f2f2;
                }
                .button {
                  padding: 10px 20px;
                  background-color: #AA731F;
                  color: #fff;
                  border: none;
                  cursor: pointer;
                  margin-top: 10px;
                  border-radius: 5px;
                }
                .button:disabled {
                  background-color: #ccc;
                  cursor: not-allowed;
                }
                #selectAll {
                  margin-right: 5px;
                }
                .heading {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 20px;
                  margin-right: 20px;
                }
              </style>
            </head>
            <body>
              <div class="heading">
                <h1>Approved Quotes</h1>
                <button id="printButton" class="button">Print</button>
              </div>
              <table class="table-wrapper">
                <thead>
                  <tr>
                    <th><input checked type="checkbox" id="selectAll"></th>
                    <th>Sr. No.</th>
                    <th>Name Of Firm</th>
                    <th>Vendor Code</th>
                    <th>Approved Date</th>
                    <th>Item Details</th>
                    <th>Numbers</th>
                    <th>Length</th>
                    <th>Breadth</th>
                    <th>Height</th>
                    <th>Quantity</th>
                    <th>Unit</th>
                    <th>Rate</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  ${tableData
                    .map((item, index) => {
                      return `<tr>
                        <td><input checked type="checkbox" class="rowCheckbox"></td>
                        <td class="sr-no">${index + 1}</td>
                        <td>${item?.company?.code}</td>
                        <td>${item?.vendor_code}</td>
                        <td>${
                          item?.approved_date
                            ? moment(item?.approved_date).format("DD MMM YYYY")
                            : ""
                        }</td>
                        <td>${item?.item_name}</td>
                        <td>${item?.nos}</td>
                        <td>${item?.length}</td>
                        <td>${item?.breadth}</td>
                        <td>${item?.height}</td>
                        <td>${item?.qty}</td>
                        <td>${item?.unit}</td>
                        <td>${item?.rate}</td>
                        <td>${item?.amount}</td>
                      </tr>`;
                    })
                    .join("")}
                </tbody>
              </table>
              <script>
                const printButton = document.getElementById('printButton');
                const checkboxes = document.querySelectorAll('.rowCheckbox');
                const selectAllCheckbox = document.getElementById('selectAll');
  
                function updatePrintButtonState() {
                  const anyChecked = Array.from(checkboxes).some(
                    (checkbox) => checkbox.checked
                  );
                  printButton.disabled = !anyChecked;
                }

                function updateSerialNumbers() {
                const visibleRows = Array.from(document.querySelectorAll('tbody tr')).filter(row => row.style.display !== 'none');
                visibleRows.forEach((row, index) => {
                  row.querySelector('.sr-no').textContent = index + 1;
                });
                }
  
                checkboxes.forEach((checkbox) => {
                  checkbox.addEventListener('change', () => {
                    updatePrintButtonState();
                    if (!checkbox.checked) {
                      selectAllCheckbox.checked = false;
                    }
                  });
                });
  
                selectAllCheckbox.addEventListener('change', () => {
                  const isChecked = selectAllCheckbox.checked;
                  checkboxes.forEach((checkbox) => {
                    checkbox.checked = isChecked;
                  });
                  updatePrintButtonState();
                });
  
                printButton.addEventListener('click', () => {
                  checkboxes.forEach((checkbox) => {
                    if (!checkbox.checked) {
                      checkbox.parentElement.parentElement.style.display = 'none';
                    }
                  });
                  updateSerialNumbers();
                  document.querySelectorAll('input[type="checkbox"]').forEach((el) => 
                    el.parentElement.remove()
                  );
                  printButton.remove();
                  window.print();
                  window.close();
                });
              </script>
            </body>
          </html>
        `;
        // Write content to the new window
        printWindow.document.open();
        printWindow.document.write(tableHtml);
        printWindow.document.close();
      } else {
        const exportData = tableData.map((item, index) => {
          const data = {
            "Sr.No.": parseInt(page) * limit + index + 1,
            "Name Of Firm": item?.company?.code,
            "Vendor Code": item?.vendor_code,
            "Approved Date": item?.approved_date
              ? moment(item?.approved_date).format("DD MMM YYYY")
              : "",
            "Item Details": item?.item_name,
            Numbers: item?.nos,
            Length: item?.length,
            Breadth: item?.breadth,
            Height: item?.height,
            Quantity: item?.qty,
            Unit: item?.unit,
            Rate: item?.rate,
            Amount: item?.amount,
          };

          return data;
        });
        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, `approved_quotes.xlsx`);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });

      if (error?.response?.status == 403) {
        console.log("error", error);
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      if (isPrint) {
        setIsPrinting(false);
      } else {
        setIsExporting(false);
      }
    }
  }

  async function handleApprove(row) {
    setDisplayModal({
      ...displayModal,
      quote_item: displayModal?.quote_item.map((item) => {
        if (item.id == row.id) {
          item.isGettingDetails = true;
        }
        return item;
      }),
    });
    try {
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/updateapprovequote`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: {
          quote_id: displayModal.id,
          data: [
            {
              id: row.id,
              nos: row.nos,
              qty: row.qty,
              rate: row.rate,
              amount: row.amount,
            },
          ],
        },
      });

      if (response?.data?.success) {
        setshowSuccess(true);
        setSuccessMessagePopup(response.data?.msg);
        //remove current row from displayModal
        let newQuoteItem = displayModal?.quote_item.filter((item) => {
          if (item.id == row.id) {
            return false;
          }
          return true;
        });
        console.log("newQuoteItem", newQuoteItem);
        setDisplayModal({
          ...displayModal,
          quote_item: newQuoteItem,
        });

        setTimeout(() => {
          handleCloseSuccess();
        }, 5000);
      } else {
        setError({ title: "Error", err: response?.data?.msg });
        setShowErrModal(true);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      // setDisplayModal({
      //   ...displayModal,
      //   quote_item: displayModal?.quote_item.map((item) => {
      //     if (item.id == row.id) {
      //       item.isGettingDetails = false;
      //     }
      //     return item;
      //   }),
      // }); // setPage("0");
      getRows(
        selectedFromDate,
        selectedToDate,
        selectedSite,
        selectedVendor,
        quoteType,
        selectedArticle
      );
    }
  }

  async function getContractors(type) {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/vendorcontractorlist`,
        method: "GET",
        params: {
          ledger_master: type,
        },
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        return response.data?.data;
      } else {
        return [];
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return [];
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }
  async function getArticles() {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getitemslist`,
        method: "GET",

        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        setArticlesList(response.data?.data);
      } else {
        setArticlesList([]);
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }

  useEffect(() => {
    getRows(
      selectedFromDate,
      selectedToDate,
      selectedSite,
      selectedVendor,
      quoteType,
      selectedArticle
    );
  }, [page, limit]);

  // useEffect(() => {
  //   if (!searchInput) {
  //     getRows();
  //   }
  // }, [searchInput]);

  useEffect(() => {
    (async () => {
      // setSiteList((await getContractors("Office")) || []);
      setOptionsFirm(await getContractors("Firm"));
      // setOptionsVendor(await getContractors("Vendor,Contractor,Subcontractor"));
      setOptionsVendor(await getContractors("Contractor,Subcontractor"));
    })();
    getArticles();
  }, []);

  return (
    <>
      <div className="inner-box pt-3">
        <div className="">
          <h1 className="heading p-0 m-0 text-center">
            Contractor/Supplier Quote Approved
          </h1>
        </div>
        <div className="d-flex pt-2 mb-4">
          {/* <div className="maxw-300 d-flex ">
            <input
              type="search"
              className="input-box "
              placeholder="Search"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <button
              disabled={!searchInput.length}
              onClick={() => {
                getRows();
              }}
              className="button button-brown ms-2"
            >
              <span>
                <svg className="icon">
                  <use href="#icon_search"></use>
                </svg>
              </span>
            </button>
          </div> */}
          {/* <div className="ms-auto">
            <button
              className="button button-brown px-4"
              type="button"
              onClick={() => setDisplayModal(true)}
            >
              Download sample
            </button>
            <button className="button button-brown px-4 ms-5" type="button">
              Bulk upload
            </button>
            <button
              className="button button-brown px-4 ms-5"
              type="button"
              onClick={handleShow}
            >
              + Add Bills
            </button>
          </div> */}
        </div>
        <div className="d-flex">
          <div className="w-45 me-4">
            <label htmlFor="" className="mb-10 d-block">
              Choose Firm
            </label>

            <Select
              value={selectedSite}
              onChange={(option) => {
                setSelectedSite(option);
                if (page == 0) {
                  getRows(
                    selectedFromDate,
                    selectedToDate,
                    option,
                    selectedVendor,
                    quoteType,
                    selectedArticle
                  );
                } else {
                  setPage(0);
                }
              }}
              options={optionsFirm.map((user) => ({
                value: user?.code,
                label: user?.code, //+ "-" + user?.name,
              }))}
              isSearchable
              isClearable
            />
          </div>
          <div className="w-45 me-4">
            <label htmlFor="" className="mb-10 d-block">
              Choose Contractor
            </label>

            <Select
              value={selectedVendor}
              onChange={(option) => {
                setSelectedVendor(option);
                if (page == 0) {
                  getRows(
                    selectedFromDate,
                    selectedToDate,
                    selectedSite,
                    option,
                    quoteType,
                    selectedArticle
                  );
                } else {
                  setPage(0);
                }
              }}
              options={optionsVendor.map((user) => ({
                value: user?.code,
                label: user?.code, // + "-" + user?.name,
              }))}
              isSearchable
              isClearable
            />
          </div>
          <div className="w-45 me-4">
            <label htmlFor="" className="mb-10 d-block">
              Choose Item
            </label>

            <Select
              value={selectedArticle}
              onChange={(option) => {
                setSelectedArticle(option);
                if (page == 0) {
                  getRows(
                    selectedFromDate,
                    selectedToDate,
                    selectedSite,
                    selectedVendor,
                    quoteType,
                    option
                  );
                } else {
                  setPage(0);
                }
              }}
              options={articlesList.map((user) => ({
                value: user?.item_name,
                label: user?.item_name, // + "-" + user?.name,
              }))}
              isSearchable
              isClearable
            />
          </div>
          {/* <div className="w-45 me-4">
            <label htmlFor="" className="mb-10 d-block">
              Choose Quote Type
            </label>

            <select
              className="input-box  mx-auto"
              aria-label="rateSelect"
              onChange={(e) => {
                setQuoteType(e.target.value);
                if (page == 0) {
                  getRows(
                    selectedFromDate,
                    selectedToDate,
                    selectedSite,
                    selectedVendor,
                    e.target.value
                  );
                } else {
                  setPage(0);
                }
              }}
              value={quoteType}
            >
              <option selected value="measurements">
                Rates In Measurements
              </option>
              <option value="quantity">Rates In Quantity</option>
            </select>
          </div> */}

          {/* <div className="w-45 mr-30">
            <label htmlFor="" className="mb-10 d-block">
              Date
            </label>
            <RangePicker
              className="input-box gray"
              value={[selectedFromDate, selectedToDate]}
              format={"DD MMM YYYY"}
              onChange={(dates) => {
                setSelectedFromDate(dates?.length ? dates[0] : "");
                setSelectedToDate(dates?.length ? dates[1] : "");
              }}
            />
          </div> */}

          <select
            value={""}
            onChange={(e) => {
              if (e.target.value == "Print Selection") {
                printOrExportData(
                  true,
                  selectedFromDate,
                  selectedToDate,
                  selectedSite,
                  selectedVendor,
                  quoteType,
                  selectedArticle,
                  rows
                );
              } else if (e.target.value == "Print All") {
                printOrExportData(
                  true,
                  selectedFromDate,
                  selectedToDate,
                  selectedSite,
                  selectedVendor,
                  quoteType,
                  selectedArticle
                );
              }
            }}
            className="button button-brown mr-30 ms-auto align-self-end"
          >
            <option className="d-none" value="">
              {isPrinting ? <Loader /> : "Print"}
            </option>
            <option value="Print Selection">Print Selection</option>
            <option value="Print All">Print All</option>
          </select>
        </div>

        <div ref={componentRef} className="tbl-scroller">
          <table className="table-wrapper">
            <thead>
              <tr>
                <th className="srtd">Sr. No.</th>
                <th>Name Of Firm</th>
                <th>Contractor Code</th>
                <th>Approved Date</th>
                <th>Item Details</th>
                {/* {quoteType == "measurements" && (
                    <> */}
                <th>Numbers</th>
                <th>Length</th>
                <th>Breadth</th>
                <th>Height</th>
                {/* </>
                  )} */}
                <th>Quantity</th>
                <th>Unit</th>
                <th>Rate</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody className="tbody-gap">
              <tr>
                <td colSpan="13"></td>
              </tr>
            </tbody>

            {!isGettingRows && (
              <tbody>
                {rows.map((row, index) => (
                  <tr key={row?.id}>
                    <td>{index + 1}</td>
                    <td>{row?.company?.code}</td>
                    <td>{row?.vendor_code}</td>
                    <td>
                      {row?.approved_date
                        ? moment(row?.approved_date).format("DD MMM YYYY")
                        : ""}
                    </td>
                    <td>{row?.item_name}</td>
                    <td>{row?.nos}</td>
                    <td>{row?.length}</td>
                    <td>{row?.breadth}</td>
                    <td>{row?.height}</td>
                    <td>{row?.qty}</td>
                    <td>{row?.unit}</td>
                    <td>{row?.rate}</td>
                    <td>{row?.amount}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {isGettingRows && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader color={"#aa741f"} />
            </div>
          )}
          {!rows.length && !isGettingRows && (
            <div className="d-flex jc-center">
              <span>No data found</span>
            </div>
          )}
        </div>
        <form className="d-flex jc-between pt-30">
          <select
            value={limit}
            onChange={(e) => {
              setLimit(e.target.value);
              setPage(0);
            }}
            name="cars"
            id="cars"
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
            <option value={60}>60</option>
            <option value={70}>70</option>
            <option value={90}>90</option>
            <option value={100}>100</option>
          </select>
          <div className="pagination">
            <button
              disabled={parseInt(page) === 0}
              onClick={() => setPage(parseInt(page) - 1)}
              type="button"
              className="page-leftright"
            >
              <svg className="icon fs-24">
                <use href="#icon_leftarrow"></use>
              </svg>
            </button>
            <span>
              Page {parseInt(page) + 1} of {Math.ceil(totalCount / limit)}
            </span>
            <button
              disabled={parseInt(page) + 1 === Math.ceil(totalCount / limit)}
              onClick={() => setPage(parseInt(page) + 1)}
              type="button"
              className="page-leftright"
            >
              <svg className="icon fs-24">
                <use href="#icon_rightarrow"></use>
              </svg>
            </button>
            <button
              disabled={parseInt(page) + 1 === Math.ceil(totalCount / limit)}
              onClick={() => setPage(Math.ceil(totalCount / limit) - 1)}
              type="button"
              className="page-leftright"
            >
              <svg className="icon fs-24">
                <use href="#icon_roundArrow"></use>
              </svg>
            </button>
          </div>
        </form>
      </div>

      {/* modal himcon */}
      <Modal
        show={displayModal}
        centered
        onHide={() => {
          setDisplayModal(false);
        }}
        aria-labelledby="example-custom-modal-styling-title"
        className="modal-min-w90 modal-min-h90"
      >
        <Modal.Header closeButton className="border-0">
          <div className="d-flex text-center w-100">
            <div className="mx-auto fs-22 fw-500">
              Quote By: {displayModal?.vendor?.code} | Requested By:{" "}
              {displayModal?.employee?.code}
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="text-gray pt-0 ">
          <div className="tbl-scroller">
            <table className="table-wrapper">
              <thead>
                <tr>
                  <th className="srtd">Sr. No.</th>
                  <th>Name Of Firm</th>
                  {/* <th>Item No.</th> */}
                  <th>Item Details</th>
                  {quoteType == "measurements" && (
                    <>
                      <th>Numbers</th>
                      <th>Length</th>
                      <th>Breadth</th>
                      <th>Height</th>
                    </>
                  )}
                  <th>Quantity</th>
                  <th>Unit</th>
                  <th>Rate</th>
                  <th>Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="tbody-gap">
                <tr>
                  <td colSpan="13"></td>
                </tr>
              </tbody>
              <tbody>
                {displayModal?.quote_item?.map((row, index) => (
                  <tr key={row?.id}>
                    <td>{index + 1}</td>
                    <td>
                      {row?.company?.code}
                      {/* - {row?.company?.name} */}
                    </td>
                    {/* <td>{row?.item_no}</td> */}
                    <td>{row?.item_name}</td>
                    {quoteType == "measurements" && (
                      <>
                        {/* <td>
                          <input
                            disabled={row?.status == 2}
                            className="input-box"
                            type="number"
                            value={row?.nos}
                            onChange={(e) => {
                              let nos = parseFloat(e.target.value);
                              row.nos = nos;
                              row.qty =
                                nos * row?.length * row?.breadth * row?.height;
                              row.amount =
                                nos *
                                row?.length *
                                row?.breadth *
                                row?.height *
                                row?.rate;
                              setDisplayModal({
                                ...displayModal,
                              });
                            }}
                          />
                        </td> */}
                        <td>{row?.nos}</td>
                        <td>{row?.length}</td>
                        <td>{row?.breadth}</td>
                        <td>{row?.height}</td>
                      </>
                    )}
                    <td>
                      {quoteType == "measurements"
                        ? row?.qty
                        : // <input
                          //   disabled={row?.status == 2}
                          //   className="input-box"
                          //   type="number"
                          //   value={row?.qty}
                          //   onChange={(e) => {
                          //     let qty = parseFloat(e.target.value);
                          //     row.qty = qty;
                          //     row.amount = qty * row.rate;
                          //     setDisplayModal({
                          //       ...displayModal,
                          //     });
                          //   }}
                          // />
                          row?.qty}
                    </td>
                    <td>{row?.unit}</td>
                    <td>
                      {/* <input
                        disabled={row?.status == 2}
                        className="input-box"
                        type="number"
                        value={row?.rate}
                        onChange={(e) => {
                          let rate = parseFloat(e.target.value);
                          row.rate = rate;
                          row.amount =
                            quoteType == "measurements"
                              ? row?.nos *
                                row?.length *
                                row?.breadth *
                                row?.height *
                                rate
                              : rate * row.qty;
                          setDisplayModal({
                            ...displayModal,
                          });
                        }}
                      /> */}
                      {row?.rate}
                    </td>
                    <td>{row?.amount}</td>
                    <td>
                      {row?.status == 2
                        ? "Approved"
                        : // <button
                          //   disabled={row?.isGettingDetails}
                          //   onClick={() => handleApprove(row)}
                          //   className="bg-brown"
                          //   type="button"
                          // >
                          //   {row?.isGettingDetails ? <Loader /> : "Approve"}
                          // </button>
                          "Approved"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* {isGettingDetails && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader color={"#aa741f"} />
              </div>
            )} */}
            {!displayModal?.quote_item?.length && (
              <div className="d-flex jc-center">
                <span>No data found</span>
              </div>
            )}
            {/* <tr>
                <td>BMK22334</td>
                <td className="tbl-br-l">Rs 1400</td>
                <td className="tbl-br-l">8-10-2024</td>
                <td className="tbl-br-l">15-10-2024</td>
                <td className="tbl-br-l">10-10-2024</td>
                <td className="tbl-br-l">ALT112255</td>
              </tr> */}
          </div>
        </Modal.Body>
      </Modal>

      <SuccessModal
        showSuccess={showSuccess}
        successMessagePopup={successMessagePopup}
        handleCloseSuccess={handleCloseSuccess}
      />
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
    </>
  );
}

export default VendorQuotesApproved;
