import { useEffect, useMemo } from "react";
import { Link, NavLink, useOutletContext } from "react-router-dom";
import { isHo } from "../utils/comman";

function AsideBar({ reports, menus }) {
  const hasMenuAccess = (menuKey) => {
    return isHo() ? true : menus?.some((menu) => menu.menu_key === menuKey);
  };

  const moreLink = (env, id) => {
    if (!env.target.matches(".active")) {
      document.querySelectorAll(".nav-toggle").forEach((e) => {
        e.classList.remove("active");
      });
      document.querySelectorAll(".navmore-link").forEach((e) => {
        e.classList.remove("active");
      });
      env.target.classList.add("active");
      document.getElementById(id).classList.add("active");
    } else {
      env.target.classList.remove("active");
      document.getElementById(id).classList.remove("active");
    }
  };

  const menuToggle = () => {
    document.body.classList.toggle("shrink-menu");
  };

  return (
    <>
      <aside>
        <div className="dashboard-logo">
          {/* <Link to="/">
            <img src={`${window.origin}/images/logo.png`} alt="logo" />
          </Link> */}
          <button
            type="button"
            className="ms-auto"
            onClick={() => menuToggle()}
          >
            <svg className="icon">
              <use href="#icon_menu"></use>
            </svg>
          </button>
        </div>
        <ul className="d-flex flex-column justify-content-between">
          <div>
            <li>
              <NavLink to="/">
                <span>
                  <svg className="icon">
                    <use href="#icon_code_sheet"></use>
                  </svg>
                </span>
                <strong>Dashboard</strong>
              </NavLink>
            </li>

            {hasMenuAccess("code_sheet") && (
              <li>
                <div
                  className="nav-link nav-toggle"
                  onClick={(e) => moreLink(e, "more10")}
                >
                  <span>
                    <svg className="icon">
                      <use href="#icon_code_sheet"></use>
                    </svg>
                  </span>
                  <strong>Code Sheet</strong>
                </div>
                <div className="navmore-link" id="more10">
                  {hasMenuAccess("office") && (
                    <NavLink to="/profile/office">Office</NavLink>
                  )}

                  {hasMenuAccess("vendor") && (
                    <NavLink to="/profile/vendor">Vendor</NavLink>
                  )}
                  {hasMenuAccess("contractor_subpplier") && (
                    <NavLink to="/profile/contractor-supplier">
                      Contractor / Supplier
                    </NavLink>
                  )}
                </div>
              </li>
            )}

            {hasMenuAccess("daily_transaction") && (
              <li>
                <div
                  className="nav-link nav-toggle"
                  onClick={(e) => moreLink(e, "more2")}
                >
                  <span>
                    <svg className="icon">
                      <use href="#icon_daily_transaction_head_office"></use>
                    </svg>
                  </span>
                  <strong>Daily Transactions</strong>
                </div>
                <div className="navmore-link" id="more2">
                  {hasMenuAccess("head_office_transaction") && (
                    <NavLink to="/daily_transaction_head_office">
                      Head Office Transactions
                    </NavLink>
                  )}
                  {hasMenuAccess("cash_track") && (
                    <NavLink to="/cash_track/cash_track_office">
                      Cash Track
                    </NavLink>
                  )}
                  {hasMenuAccess("user_transaction") && (
                    <NavLink to="/cash_track/daily_cash_transactions">
                      User Transaction
                    </NavLink>
                  )}
                  {hasMenuAccess("mismatched") && (
                    <NavLink to="/cash_track/mismatched">Mismatched</NavLink>
                  )}
                  {hasMenuAccess("daily_cash_requirement") && (
                    <NavLink to="/cash_track/daily_cash_requirement">
                      Daily Cash Requirement
                    </NavLink>
                  )}
                </div>
              </li>
            )}

            {hasMenuAccess("purpose_payment") && (
              <li>
                <NavLink to="/purpose_of_payment">
                  <span>
                    <svg className="icon">
                      <use href="#icon_purpose"></use>
                    </svg>
                  </span>
                  <strong>Purpose Of Payment</strong>
                </NavLink>
              </li>
            )}

            {hasMenuAccess("site_item_master") && (
              <li>
                <NavLink to="/home_expenses/item_master">
                  <span>
                    <svg className="icon">
                      <use href="#icon_home_expenses"></use>
                    </svg>
                  </span>
                  <strong>Article Creation</strong>
                </NavLink>
              </li>
            )}
            {hasMenuAccess("mismatch") && (
              <li>
                <NavLink to="/mismatch/site_home_expenses">
                  <span>
                    <svg className="icon">
                      <use href="#icon_discrepancy"></use>
                    </svg>
                  </span>
                  <strong>Mismatch</strong>
                </NavLink>
              </li>
            )}

            {/* {hasMenuAccess("mismatch") && (
              <li>
                <div
                  className="nav-link nav-toggle"
                  onClick={(e) => moreLink(e, "more4")}
                >
                  <span>
                    <svg className="icon">
                      <use href="#icon_discrepancy"></use>
                    </svg>
                  </span>
                  <strong>Mismatch</strong>
                </div>
                <div className="navmore-link" id="more4">
                  {hasMenuAccess("home_expences_mismatch") && (
                    <NavLink to="/discrepancy/purchase">Home Expenses</NavLink>
                  )}
                  {hasMenuAccess("site_mismatch") && (
                    <NavLink to="/discrepancy/site">Site</NavLink>
                  )}
                </div>
              </li>
            )} */}

            {hasMenuAccess("conclusion") && (
              <li>
                <NavLink to="/conclusion/site_home_expenses">
                  <span>
                    <svg className="icon">
                      <use href="#icon_conclusion"></use>
                    </svg>
                  </span>
                  <strong>Conclusion</strong>
                </NavLink>
              </li>
            )}

            {/* {hasMenuAccess("conclusion") && (
              <li>
                <div
                  className="nav-link nav-toggle"
                  onClick={(e) => moreLink(e, "more5")}
                >
                  <span>
                    <svg className="icon">
                      <use href="#icon_conclusion"></use>
                    </svg>
                  </span>
                  <strong>Conclusion</strong>
                </div>
                <div className="navmore-link" id="more5">
                  {hasMenuAccess("home_expences_conclusion") && (
                    <NavLink to="/conclusion/purchase">Home Expenses</NavLink>
                  )}
                  {hasMenuAccess("site_conclusion") && (
                    <NavLink to="/conclusion/site">Site</NavLink>
                  )}
                </div>
              </li>
            )} */}

            {hasMenuAccess("liabilities") && (
              <li>
                <div
                  className="nav-link nav-toggle"
                  onClick={(e) => moreLink(e, "more6")}
                >
                  <span>
                    <svg className="icon">
                      <use href="#icon_all_bill"></use>
                    </svg>
                  </span>
                  <strong>Liabilities</strong>
                </div>
                <div className="navmore-link" id="more6">
                  {hasMenuAccess("all_bills") && (
                    <NavLink to="/bills/all_bill">All Bills</NavLink>
                  )}
                  {hasMenuAccess("paid_bills") && (
                    <NavLink to="/bills/paid_bill">Paid Bills</NavLink>
                  )}
                </div>
              </li>
            )}

            {reports?.filter((report) => report?.web_status)?.length > 0 &&
              hasMenuAccess("reports") && (
                <li>
                  <div
                    className="nav-link nav-toggle"
                    onClick={(e) => moreLink(e, "more1")}
                  >
                    <span>
                      <svg className="icon">
                        <use href="#icon_report"></use>
                      </svg>
                    </span>
                    <strong>Reports</strong>
                  </div>
                  <div className="navmore-link" id="more1">
                    {reports
                      ?.filter(
                        (report) =>
                          report?.web_status &&
                          hasMenuAccess(report?.report_code)
                      )
                      ?.map((report, index) => {
                        return (
                          <NavLink
                            key={report?.report_code}
                            to={`/report/${report?.report_code}`}
                          >
                            {report?.report_name}
                          </NavLink>
                        );
                      })}
                  </div>
                </li>
              )}

            {hasMenuAccess("contractor_supplier_rate") && (
              <li>
                <div
                  className="nav-link nav-toggle"
                  onClick={(e) => moreLink(e, "more8")}
                >
                  <span>
                    <svg className="icon">
                      <use href="#icon_vendor_quotes"></use>
                    </svg>
                  </span>
                  <strong>Contractor/Supplier Rate Approved</strong>
                </div>
                <div className="navmore-link" id="more8">
                  {hasMenuAccess("quote_approve") && (
                    <NavLink to="/contractor_supplier_rate_approved/quote_to_approve">
                      Quote to approve
                    </NavLink>
                  )}
                  {hasMenuAccess("approved_quote") && (
                    <NavLink to="/contractor_supplier_rate_approved/approved_quote">
                      Approved quote
                    </NavLink>
                  )}
                </div>
              </li>
            )}
          </div>
          <div>
            {hasMenuAccess("exports") && (
              <li>
                <NavLink to="/export">
                  <span>
                    <svg className="icon">
                      <use href="#icon_export"></use>
                    </svg>
                  </span>
                  <strong>Export</strong>
                </NavLink>
              </li>
            )}
            {hasMenuAccess("id_verification") && (
              <li>
                <NavLink to="/id_verification">
                  <span>
                    <svg className="icon">
                      <use href="#icon_id_verification"></use>
                    </svg>
                  </span>
                  <strong>ID Verification</strong>
                </NavLink>
              </li>
            )}
            <li>
              <NavLink
                onClick={() => {
                  localStorage.removeItem("him_user_token");
                  localStorage.removeItem("him_user_data");
                }}
                to="/login"
              >
                <span>
                  <svg className="icon">
                    <use href="#icon_logout"></use>
                  </svg>
                </span>
                <strong>Logout </strong>
              </NavLink>
            </li>
          </div>
        </ul>
      </aside>
    </>
  );
}

export default AsideBar;
